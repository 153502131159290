%home-content {
  width: var(--home-width-content);
  margin: 0 auto;

  @media (max-device-width: 670px) and (max-width: 670px) and (orientation: portrait) {
    width: auto;
    padding: 0 2rem;
  }
}

%one-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%warn-char {
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--linear-opacity-red);

  svg {
    width: 12px;
  }

  @media (max-device-width: 670px) and (max-width: 670px) and (orientation: portrait) {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.4rem;
    border-radius: 0.4rem;

    svg {
      width: 1.2rem;
    }
  }
}
