@import "../mixin";

.login-modal {
  outline: none;
  position: absolute;
  width: 450px;
  border-radius: 20px;
  left: calc(50vw - 225px);
  overflow: auto;
  top: 5svh;
  max-height: 90svh;
  padding: 24px;
  background: var(--bg-dark-black-3);
  box-shadow: var(--box-shadow-default);
  @include columnGap(20px);

  .ant-segmented-group {
    label {
      width: 50%;
      padding: 16px;
      display: flex;
      justify-content: center;
      background: transparent;
    }
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__remember {
      display: flex;
      gap: 8px;
      color: var(--label-text-4);
      input {
        width: fit-content;
        min-width: auto !important;
      }
      label {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .login-modal__reset-pas {
      background: var(--primary);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.56px;
    text-align: center;
    color: var(--white);
  }

  .re-captcha {
    display: flex;
    justify-content: center;
  }

  &__reset-pas {
    display: block;
    color: var(--red);
    font-size: 16px;
    line-height: 24px;
    width: fit-content;
    margin-left: auto;
    background-color: transparent;
    padding: 0 !important;
  }

  &__form {
    @include columnGap(16px);

    .login-modal__button {
      min-width: 300px;
    }

    button {
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      padding: 8px 20px;
      border-radius: 8px;
      background: var(--primary);
    }
  }

  .reset-password {
    @include columnGap(28px);

    &__title {
      text-align: center;
      line-height: 120%;
    }

    &__content {
      @include columnGap(20px);
    }

    p {
      color: var(--dark-gray-2);
    }
  }

  .reset-password__title {
    color: var(--white);
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.56px;
    padding-bottom: 32px;
    border-bottom: 4px solid var(--primary);
  }

  .res-pas__button {
    background: var(--primary);
    padding: 16px;
    font-weight: 700;
    font-size: 18px;
  }

  .reset-password__content p {
    color: var(--label-text-4);
    font-size: 14px;
    font-weight: 500;
  }

  @include l1220 {
    .ant-segmented .ant-segmented-item {
      font-size: 14px;
    }
  }

  @media (max-height: 695px) {
    width: 360px;
    left: calc(50vw - 180px);
    gap: 16px;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__form {
      gap: 12px;
    }

    .ant-segmented-group label {
      padding: 8px;
    }

    &__button {
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .custom-input {
      &__title {
        font-size: 14px;
      }

      input {
        padding: 12px 20px;
        font-size: 12px;
      }

      .password__hide__btn {
        display: flex;
        align-items: center;
        top: 0;
      }
    }
  }

  @include sm {
    width: 37rem;
    border-radius: 2rem;
    left: calc(50vw - 18.5rem);
    top: 60px;
    padding: 2rem;
    @include columnGap(2rem);

    .text-error {
      font-size: 1.4rem;
    }

    .ant-segmented-group {
      label {
        padding: 1.6rem;
      }
    }

    &__action {
      &__remember {
        gap: 0.8rem;
        label {
          font-size: 1.6rem;
        }
      }

      .login-modal__reset-pas {
        font-size: 1.6rem;
      }
    }

    &__title {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    &__reset-pas {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &__form {
      @include columnGap(1.6rem);

      .login-modal__button {
        min-width: 30rem;
        font-size: 1.8rem;
      }

      button {
        font-size: 2.4rem;
        line-height: 3.4rem;
        padding: 0.8rem 2rem;
        border-radius: 0.8rem;
      }
    }

    .reset-password {
      @include columnGap(2.8rem);

      &__content {
        @include columnGap(2rem);
      }
    }

    .reset-password__title {
      font-size: 2.8rem;
      padding-bottom: 3.2rem;
    }

    .res-pas__button {
      padding: 1.6rem;
      font-size: 1.8rem;
    }

    .reset-password__content p {
      font-size: 1.4rem;
    }
  }

  @include b2k {
    width: 45rem;
    border-radius: 2rem;
    left: calc(50vw - 22.5rem);
    padding: 2.4rem;
    @include columnGap(2rem);

    .ant-segmented-group {
      label {
        padding: 1.6rem;
      }
    }

    &__action {
      &__remember {
        gap: 0.8rem;

        label {
          font-size: 1.6rem;
        }
      }

      .login-modal__reset-pas {
        font-size: 1.6rem;
        letter-spacing: -0.016rem;
      }
    }

    &__title {
      font-size: 2.8rem;
      line-height: 3.2rem;
      letter-spacing: -0.05rem;
    }

    &__reset-pas {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &__form {
      @include columnGap(1.6rem);

      .login-modal__button {
        min-width: 30rem;
      }

      button {
        font-size: 2rem;
        line-height: 3.4rem;
        padding: 0.8rem 2rem;
        border-radius: 1.6rem;
      }
    }

    .reset-password {
      @include columnGap(1.4rem);

      &__title {
        font-size: 2.8rem;
        padding-bottom: 1.4rem;
        border-width: 0.02rem;
      }

      &__content {
        @include columnGap(2rem);
      }
    }

    .res-pas__button {
      padding: 1.6rem;
      font-size: 1.8rem;
    }

    .reset-password__content p {
      font-size: 1.4rem;
    }
  }
}
