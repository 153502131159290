@import "../mixin";
@import "../template.scss";

.general {
  &__content {
    margin-top: 52px;
    display: flex;
    gap: 64px;
  }

  //__img__container
  &__img__container {
    position: relative;
    cursor: pointer;

    .general__img__action {
      overflow: hidden;
      width: 280px;
      height: 280px;
      background: transparent;
      border-radius: 50%;
      border: 10px solid var(--red);
      padding: 16px;
    }
  }

  &__img__pen {
    position: absolute;
    cursor: pointer;
    bottom: 0px;
    right: -12px;
    background-color: var(--bg-dark-black-3);
    border-radius: 50%;
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--red);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  //__info
  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
    margin: auto 0;
    width: 100%;

    &__balance {
      &__title {
        color: var(--label-text-4);
        font-weight: 500;
        font-size: 14px;
      }

      &__content {
        margin-top: 8px;
      }
    }

    &__warn {
      @extend %warn-char;
    }

    &__description {
      display: flex;
      gap: 4px;

      &__text {
        font-size: 14px;
        button {
          background-color: transparent;
          color: var(--primary-pale);
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @include lsm {
    &__img__container {
      .general__img__action {
        width: 200px;
        height: 200px;
        border: 10px solid var(--red);
        padding: 16px;
      }
    }

    &__img__pen {
      bottom: 0px;
      right: -12px;

      width: 60px;
      height: 60px;

      &__circle {
        width: 44px;
        height: 44px;
        svg {
          width: 28px;
        }
      }
    }
  }

  @include l670 {
    &__content {
      flex-direction: column;

      .general__img__container {
        width: fit-content;
        margin: auto;
      }
    }
  }

  @include sm {
    &__info {
      &__title {
        font-size: 2.4rem;
      }
      &__warn {
        width: 2rem;
        height: 2rem;
        svg {
          width: 1.2rem;
          height: auto;
        }
      }

      &__description__text {
        font-size: 1.6rem;
      }

      &__balance {
        &__title {
          font-size: 1.4rem;
        }
        &__content {
          margin-top: 1rem;
          .balance__content {
            width: 100%;
            margin: auto;
            .balance__value {
              font-size: 1.8rem;
            }
          }
          button {
            border-radius: 1.6rem;
            padding: 1rem 2rem;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  @include b2k {
    &__content {
      margin-top: 5.2rem;
      gap: 6.4rem;
    }

    &__img__container {
      .general__img__action {
        width: 28rem;
        height: 28rem;
        border-width: 1rem;
        padding: 1.6rem;
      }
    }

    &__img__pen {
      right: -1.2rem;
      width: 8.2rem;
      height: 8.2rem;

      &__circle {
        width: 6rem;
        height: 6rem;
      }
    }

    &__info {
      gap: 2.4rem;

      h3 {
        font-size: 1.9rem;
      }

      &__balance {
        &__title {
          font-size: 1.4rem;
        }

        &__content {
          margin-top: 0.8rem;
        }
      }

      &__warn {
        @extend %warn-char;
      }

      &__description {
        gap: 0.04rem;

        &__text {
          font-size: 1.4rem;
        }
      }
    }
  }
}
