@import "../../../../style/mixin";

.template-modal {
  position: fixed;
  background-color: var(--bg-dark-black-3);
  box-shadow: var(--box-shadow-default);
  width: 740px;
  left: calc(50% - 370px);
  top: 5svh;
  max-height: 90svh;
  padding: 32px;
  border-radius: 20px;
  overflow: auto;
  color: var(--white);

  .modal__close {
    right: 32px;
    top: 32px;
    width: 28px;
    height: 28px;

    svg {
      width: 12px;
      height: 12px;
      display: block;
    }
  }

  .remove-cancel__buttons {
    display: flex;
    gap: 16px;
    margin-top: 8px;

    button {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 20px;
      height: 100%;
    }
  }

  &__content {
    margin-top: 24px;

    h3 {
      margin-top: 20px;
      color: var(--label-text-8);
      font-size: 18px;
      letter-spacing: -2%;
      font-weight: 500;
    }

    .pagination__content {
      padding: 16px;

      .pagination__prev,
      .pagination__next,
      .page {
        width: 40px;
        height: 40px;
      }
    }
  }

  @include lsm {
    width: 90vw;
    left: calc(50% - 45vw);
    padding: 16px;
  }

  @include smOrB2k {
    border-radius: 2rem;

    .modal__close {
      right: 3.2rem;
      top: 3.2rem;
      width: 2.8rem;
      height: 2.8rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .remove-cancel__buttons {
      gap: 1.6rem;
      margin-top: 0.8rem;

      button {
        font-size: 1.4rem;
        padding: 16px 12px;
        border-radius: 1.4rem;
      }
    }

    &__header {
      gap: 1.2rem;

      svg {
        width: 2rem;
      }
    }

    &__content {
      margin-top: 2.4rem;

      h3 {
        margin-top: 2rem;
        font-size: 1.8rem;
      }

      .pagination__content {
        padding: 1.6rem;

        .pagination__prev,
        .pagination__next,
        .page {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }

  @include sm {
    width: 100vw;
    left: 0;
    top: 5svh;
    max-height: 90svh;
    padding: 1.6rem;
    overflow: auto;

    .modal__close {
      right: 1.6rem;
      top: 1.6rem;
    }
  }

  @include b2k {
    width: 74rem;
    left: calc(50% - 37rem);
    padding: 3.2rem;

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }
}
