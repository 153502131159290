@import "./../template.scss";
@import "./header.scss";
@import "./under-header.scss";
@import "./home-fillters.scss";
@import "./menu-for-free-banner.scss";
@import "./home-content.scss";
@import "./footer.scss";
@import "./header-menu-modal.scss";

.home {
  background: var(--bg-dark-black);
  color: var(--label-text-4);
  min-height: 100vh;
  max-width: 100svw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    min-height: 400px;

    &__container {
      width: calc(100% - 256px);
    }
  }

  @include MobileHeader {
    &__content {
      &__container {
        width: 100%;
      }
    }
  }
}
