.home__models {
  &__card {
    width: 100%;
    height: 168px;
    border-radius: 8px;
    overflow: hidden;
    background: var(--label-text-1);
    box-shadow: var(--box-shadow-default);
    position: relative;
    cursor: pointer;

    &__new {
      position: absolute;
      z-index: 2;
      right: 8px;
      top: 8px;
      padding: 4px 8px;
      color: var(--white);
      background: var(--primary);
      font-size: 10px;
      font-weight: 600;
      border-radius: 4px;
      text-transform: uppercase;
      max-width: 28px;
      max-height: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6px;

      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #fff;
          fill-opacity: 0.8;
        }
      }

      &:hover svg path {
        fill-opacity: 1;
      }
    }

    &__name {
      font-family: Arial;
    }

    &__lang {
      height: 16px;
    }

    &__img {
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        z-index: 1;
        position: relative;

        &.blur-img {
          width: 100%;
          left: 0;
          right: 0;
          z-index: 0;
          position: absolute;
          filter: blur(12px);
        }
      }
    }

    &__description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      & > div {
        display: flex;
        align-items: center;
      }

      &__left {
        gap: 6px;
        font-size: 13px;
        color: var(--gray);
        display: flex;
        align-items: center;
        gap: 8px;
        letter-spacing: -0.4px;
      }
    }
    &__view {
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        path {
          fill: var(--red);
        }
      }

      &__text {
        color: var(--dark-gray);
        letter-spacing: -0.4px;
        font-size: 12px;
        font-family: Arial;
      }
    }

    &__like {
      margin-left: 8px;
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      background: var(--linear-171to71);

      &.like {
        background: var(--linear-red02to0);
        overflow: hidden;
        path {
          fill: var(--primary);
        }
      }
    }

    &__category {
      position: absolute;
      z-index: 2;
      top: 8px;
      left: 8px;
      display: flex;
      gap: 4px;
      button {
        display: flex;
        width: 20px;
        height: 20px;
        padding: 2px 2px 2px 3px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4px;
        background: var(--bg-opacity-black-3);
        box-shadow: var(-box-shadow-default-3);
        backdrop-filter: blur(45px);
        cursor: default;
      }
    }

    &__img {
      img {
        max-width: 100%;
        height: 130px;
      }
    }

    @include b2k {
      height: 15.3rem;
      border-radius: 0.8rem;

      &__new {
        right: 0.8rem;
        top: 0.8rem;
        padding: 0.4rem 0.8rem;
        font-size: 1rem;
        border-radius: 0.4rem;
        max-width: 2.8rem;
        max-height: 2.8rem;
        padding: 0.6rem;

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      &__lang {
        height: 1.6rem;
      }

      &__description {
        padding: 0.6rem 1.2rem;

        &__left {
          gap: 0.6rem;
          font-size: 1.6rem;
          gap: 0.8rem;
          letter-spacing: -0.04rem;
        }

        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }

      &__view {
        gap: 0.5rem;

        &__text {
          letter-spacing: -0.03rem;
          font-size: 1.2rem;
        }
      }

      &__like {
        margin-left: 0.8rem;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 0.4rem;
      }

      &__category {
        top: 0.8rem;
        left: 0.8rem;
        gap: 0.4rem;
        button {
          width: 2rem;
          height: 2rem;
          padding: 0.02rem;
          border-radius: 0.4rem;
        }
      }

      &__img {
        img {
          height: 11.8rem;
          width: auto;
        }
      }
    }
  }
}

.home__content__container {
  .home__models__card {
    &__dick,
    &__mobile {
      position: absolute;
      border-radius: 6px;
      z-index: 2;
      top: 0;
      left: 0;
      margin: 12px;
      width: 24px;
      height: 24px;
      background-color: var(--bg-opacity-black-3);
      display: flex;
      align-items: center;
      justify-content: center;

      &.two {
        left: 28px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.collapsed {
    .home__models__card {
      height: 208px;

      &__img {
        img {
          width: auto;
          max-width: 100%;
          height: 170px;

          &.blur-img {
            width: 100%;
          }
        }
      }

      @include lsm {
        height: 152px;

        &__img {
          min-height: 110px;
          img {
            height: 110px;
          }
        }
      }

      @include b2k {
        height: 20.8rem;

        &__dick {
          border-radius: 0.6rem;

          margin: 1.2rem;
          width: 2.4rem;
          height: 2.4rem;

          svg {
            width: 1.8rem;
            height: 1.8rem;
          }
        }

        &__img {
          img {
            height: 17rem;
          }
        }
      }
    }
  }
}
