@import "./profile.scss";

.profile-modal {
  @import "./content-general.scss";
  @import "./content-static.scss";
  @import "./content-setting.scss";
  @import "./new-avatar.scss";

  @media (max-height: 705px) {
    .select-container label,
    .container-date-picker__label,
    .ant-picker-input input,
    .custom-button,
    .setting__common .ant-picker-input input,
    .custom-attach label,
    .cus-select__value {
      font-size: 12px;
      line-height: 100%;
    }

    .statistic .cus-select__container__title,
    .statistic .custom-button,
    .container-date-picker .ant-picker,
    .custom-input input,
    .custom-attach__virtual-attach,
    .custom-input input,
    .setting__common .cus-select__container__title {
      padding: 12px 16px;
    }
  }

  @include sm {
    .modal__chapter {
      max-width: 60%;
    }

    .select-container label,
    .container-date-picker__label,
    .ant-picker-input input,
    .custom-button,
    .setting__common .ant-picker-input input,
    .custom-attach label,
    .custom-input input,
    .cus-select__value {
      font-size: 1.4rem;
    }

    .statistic .cus-select__container__title,
    .statistic .custom-button,
    .container-date-picker .ant-picker,
    .custom-input input,
    .custom-attach__virtual-attach,
    .setting__common .cus-select__container__title {
      padding: 1.6rem 2.4rem;
    }
  }
}
