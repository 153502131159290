.cus-table {
  .ant-table {
    background-color: var(--bg-dark-black-3);
    color: var(--label-text-6);

    th.ant-table-cell {
      background-color: var(--bg-dark-black-3) !important;
      color: var(--label-text-6);

      &::before {
        display: none;
      }
    }

    th {
      border: 0 !important;
      color: var(--label-text-4) !important;
      font-size: 12px;
      font-weight: 500;
    }

    .cus-table__row {
      td {
        border: 0;
        font-size: 12px;
        background-color: transparent !important;
        padding: 16px 8px;

        svg {
          width: 14px;
          height: 14px;
        }

        &:first-child {
          color: var(--white);
        }
      }
    }
  }

  @include b2k {
    .ant-table {
      th {
        font-size: 1.2rem;
      }

      .cus-table__row {
        td {
          font-size: 1.2rem;
          padding: 1.6rem 0.8rem;
        }
      }
    }
  }
}
