@import "../mixin";

.ant-segmented {
  background: var(--bg-dark-gray);
  border-radius: 16px;
  &-thumb {
    background: var(--primary) !important;
    &-motion-appear {
      border-radius: 20px !important;
    }
  }

  &-group {
    gap: 8px;
    padding: 4px;
    box-shadow: 0px 29px 58px 0px rgba(0, 0, 0, 0.25),
      0px 4px 13px 0px rgba(43, 159, 196, 0.03) inset;
  }
  &-item {
    background: var(--bg-dark-black);
    color: var(--label-text-4);
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 16px !important;
    display: flex;
    align-items: center;
    line-height: 100% !important;
    min-height: 17px !important;

    &:hover {
      color: var(--white) !important;
      svg {
        path {
          fill: var(--white);
        }
      }
    }

    // height: 100%;
    &-label {
      padding: 0 !important;
      line-height: 100% !important;
      min-height: 17px !important;
    }

    &-selected {
      font-weight: 500;
      color: var(--white) !important;
      background: var(--primary) !important;
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  @include l1220 {
    svg {
      width: 14px;
    }
    .ant-segmented-group {
      gap: 4px;
    }
    .ant-segmented-item {
      gap: 4px;
      font-size: 12px;
      border-radius: 12----px !important;
    }
  }

  @include b2k {
    border-radius: 1.6rem;
    &-thumb {
      &-motion-appear {
        border-radius: 2rem !important;
      }
    }

    &-group {
      gap: 0.8rem;
      padding: 0.4rem;
    }

    &-item {
      padding: 0.8rem 1rem;
      font-size: 1.6rem;
      border-radius: 1.6rem !important;
      min-height: 1.7rem !important;

      &-label {
        min-height: 1.7rem !important;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
