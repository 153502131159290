.home__filter {
  &__container {
    position: relative;
    top: -8px;
    padding-top: -8px;
    width: 256px;
    background: linear-gradient(180deg, var(--bg-dark-black-3), transparent);
    padding: 16px 12px;
    padding-top: 28px;
    min-height: 400px;
    height: 100%;
    transition: 0s;
    * {
      transform: 0s;
    }
    .home__filter__menu__item__buttons {
      transition: 0.2s;
    }

    .home__filter__menu__item__buttons.fixed-button {
      background-color: var(--bg-dark-gray);
      box-shadow: var(--box-shadow-default);
      bottom: -20px;
      padding: 20px 4px;
      position: fixed;
      border-radius: 12px;
      left: 0;
      width: 256px;
    }
  }

  &__menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: var(--bg-dark-black-3);

    &__li {
      & > div {
        background: var(--bg-dark-black-3);
        color: var(--label-text-6);
      }
    }

    &__item {
      &__buttons {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        button {
          width: 100%;
        }
      }

      &__range {
        width: 100%;
        margin: 28px 0;

        .ant-slider {
          &:hover {
            .ant-slider-track {
              background: var(--primary);
            }

            .ant-slider-handle::after {
              background: var(--primary);
              box-shadow: 0 0 0 2px var(--primary);
            }

            .ant-slider-rail {
              background: var(--label-text-1);
            }
          }

          &-rail {
            border-radius: 24px;
            background: var(--label-text-1);
          }

          &-handle {
            background: var(--primary);

            &:focus::after,
            &:hover::after,
            &::after {
              background: var(--primary);
              box-shadow: 0 0 0 2px var(--primary);
            }
          }

          &-track {
            background: var(--primary);
          }
        }
      }
    }

    .home__filter__menu__accordion {
      &__summary {
        padding: 8px 16px;
        min-height: auto !important;
        border-radius: 8px;
        background: var(--bg-dark-gray);
        box-shadow: var(--box-shadow-default);

        &[aria-expanded="true"] {
          border-radius: 8px;
          background: var(--linear-opacity-red);
          box-shadow: var(--box-shadow-default);
          position: relative;
          overflow: hidden;

          &::after {
            content: "";
            position: absolute;
            left: -4px;
            top: 25%;
            width: 8px;
            height: 50%;
            background: var(--primary);
            border-radius: 5px;
          }

          & > div {
            .container__icon {
              background: var(--linear-red02to0);
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        & > div {
          margin: 0;
          height: 32px;
          display: flex;
          align-items: center;
          gap: 12px;
          color: var(--white);

          .container__icon {
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid var(--border-color);
            background: var(--linear-171to71);
            box-shadow: var(--box-shadow-default-2);
          }

          .arrow {
            transition: 0.5s;
            margin-left: auto;
          }
        }
      }

      &__details {
        padding: 0;
        & > label {
          padding: 14px 16px;
          display: flex;
          & > div {
            display: flex;
            gap: 8px;
          }
        }
      }

      .MuiAccordion-region {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 4px;
      }
    }
    .home__filter__menu__item__range {
      padding: 0 8px;
    }
  }

  &__type__container {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &__child {
      label {
        display: flex;
        padding: 14px 16px;
        div {
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  @include b2k {
    &__container {
      top: -0.8rem;
      padding-top: -0.8rem;
      width: 23.6rem;
      padding: 1.6rem 1.2rem;
      padding-top: 2.8rem;
      min-height: 40rem;

      .home__filter__menu__item__buttons.fixed-button {
        bottom: -2rem;
        padding: 2rem 0.4rem;
        border-radius: 1.2rem;
        width: 23.6rem;
      }
    }

    &__menu {
      gap: 0.8rem;
      .cus-checkbox__title {
        font-size: 1.4rem;
      }
      .cus-checkbox__checkbox {
        width: 1.6rem !important;
        height: 1.6rem !important;

        &:checked:after {
          font-size: 1.2rem;
        }
      }

      &__item {
        &__buttons {
          margin: 2rem 0;
          gap: 0.8rem;

          button {
            font-size: 1.4rem;
            padding: 1.2rem 2rem;
            border-radius: 1.2rem;
          }
        }

        &__range {
          margin: 2.8rem 0;

          .ant-slider {
            &-rail {
              border-radius: 2.4rem;
            }
          }
        }
      }

      .home__filter__menu__accordion {
        &__summary {
          padding: 0.8rem 1.6rem;
          border-radius: 0.8rem;

          &[aria-expanded="true"] {
            border-radius: 0.8rem;

            &::after {
              left: -0.4rem;
              width: 0.8rem;
              border-radius: 0.5rem;
            }
          }

          & > div {
            height: 3.2rem;
            gap: 1.2rem;

            .arrow {
              width: 1.4rem;
              height: 1.4rem;
            }

            span {
              font-size: 1.6rem;
            }

            .container__icon {
              width: 3.4rem;
              height: 3.4rem;
              border-radius: 0.8rem;

              svg {
                width: 1.8rem;
                height: 1.8rem;
              }
            }
          }
        }

        &__details {
          & > label {
            padding: 1.4rem 1.6rem;
            & > div {
              gap: 0.8rem;
            }
            .cus-checkbox__title {
              font-size: 1.4rem;
            }
          }
        }

        .MuiAccordion-region {
          gap: 0.4rem;
          margin-top: 0.4rem;
        }
      }
      .home__filter__menu__item__range {
        padding: 0 0.8rem;
      }
    }

    &__type__container {
      padding-top: 0.8rem;
      gap: 0.4rem;

      & > span {
        font-size: 1.6rem;
      }

      &__child {
        label {
          padding: 1.4rem 1.6rem;
          div {
            gap: 0.8rem;
          }
        }
      }
    }
  }
}

.ant-tooltip.z-index1201 {
  z-index: 1201;
}
.ant-tooltip-inner {
  background-color: transparent !important;
  box-shadow: none !important;
  position: relative;
  top: 12px;
  font-weight: 600;
}
.ant-tooltip-arrow {
  display: none !important;
}

.ant-slider-track-draggable {
  border: 0 !important;
  top: 50%;
}
