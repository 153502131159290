@import "../mixin";

.profile-select {
  .cus-select {
    &__options {
      z-index: 10;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      &__li {
        padding: 16px;
      }
    }
    &__container__title {
      z-index: 11;
      min-width: 128px;
      .profile {
        display: flex;
        gap: 8px;
        align-items: center;

        &__btn {
          background: transparent;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid var(--red);
        }
        &__email {
          color: var(--white);
        }
      }
    }
  }
  li:hover span {
    color: var(--white);
  }

  @include MobileHeader {
    .cus-select {
      &__options {
        &__li {
          padding: 16px 8px;
        }
      }
    }
  }

  @include lsm {
    .cus-select {
      &__options__li {
        padding: 16px;

        &__icon {
          width: 20px;
          height: 20px;
          svg {
            width: 14px;
          }
        }

        &__label {
          font-size: 14px;
        }
      }
    }
  }

  @include sm {
    .cus-select {
      &__options {
        padding: 1rem;
        padding-top: 3rem;

        &__li {
          padding: 0.5rem;

          &__icon {
            width: 2.4rem;
            height: 2.4rem;

            svg {
              width: 1.4rem;
            }
          }
        }
      }
    }
  }

  @include b2k {
    .cus-select {
      &__options {
        padding-top: 4rem;

        li {
          font-size: 1.6rem;

          .cus-select__options__li__icon,
          svg {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }
      &__container__title {
        .profile {
          gap: 0.8rem;

          &__email {
            font-size: 1.6rem;
          }

          &__btn {
            width: 3rem;
            height: 3rem;
          }
        }
        .arrow {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }
}

.profile-modal {
  outline: none;
  position: absolute;
  width: 880px;
  border-radius: 20px;
  left: calc(50vw - 440px);
  max-height: 95vh;
  overflow: auto;
  top: 2.5vh;
  padding: 32px;
  background: var(--bg-dark-black-3);
  box-shadow: var(--box-shadow-default);
  color: var(--white);

  .modal__close {
    top: 32px;
    right: 32px;
  }

  .ant-segmented {
    margin-top: 28px;
    width: 100%;

    .ant-segmented-item {
      width: 33%;
      padding: 14px 56px;
      display: flex;
      justify-content: center;
      background-color: transparent;
    }
  }

  @media (max-height: 850px) {
    padding: 16px 32px;

    .modal__close {
      top: 16;
      right: 32px;
    }

    .ant-segmented {
      margin-top: 16px;
    }
  }

  @include lsm {
    position: fixed;
    width: 90vw;
    left: 5vw;
    max-height: 95vh;
    top: 2vh;
    padding: 20px;
    padding-left: 30px;
    border-radius: 20px;

    .modal__close {
      top: 20px;
      right: 20px;
    }

    .ant-segmented {
      .ant-segmented-item {
        padding: 14px;
      }
    }
  }

  @include l670 {
    width: 95vw;
    left: 2.5vw;
  }

  @include sm {
    left: 0;
    top: 0;
    width: 100svw;
    max-height: 100vh;
    padding: 2rem;

    .ant-segmented {
      margin-top: 2.8rem;

      svg {
        display: none;
      }

      .ant-segmented-item {
        padding: 1.45rem;
        width: auto;
        span {
          font-size: 1.5rem;
          line-height: 100%;
        }
      }
    }
  }

  @include b2k {
    top: 5vh;
    max-height: 90vh;
    width: 88rem;
    border-radius: 2rem;
    left: calc(50vw - 44rem);
    padding: 3.2rem;

    .modal__close {
      top: 3.2rem;
      right: 3.2rem;
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 0.4rem;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .new-avatar__back span {
      font-size: 2rem;
    }

    .ant-segmented {
      margin-top: 2.8rem;

      .ant-segmented-item {
        padding: 1.4rem 5.6rem;
      }
    }
  }
}

.russian {
  .profile-select {
    .cus-select {
      &__options {
        &__li {
          padding: 16px 8px;
          gap: 8px;
        }
      }
    }
    @include l1220 {
      .cus-select {
        &__options {
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 0;
          &__li {
            padding: 16px 0px;
            font-size: 13px;
          }
        }
      }
    }

    @include lsm {
      .cus-select {
        &__options {
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 0;
          &__li {
            padding: 16px 4px;
            &__label {
              font-size: 13px;
            }
          }
        }
      }
    }

    @include sm {
      .cus-select {
        &__options__li__label {
          font-size: 1.2rem;
        }
      }
    }

    @include b2k {
      .cus-select {
        &__options__li__label {
          font-size: 1.6rem;
        }
      }
    }
  }
}
