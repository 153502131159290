@import "./template.scss";
@import "./mixin";

* {
  margin: 0;
  padding: 0;
  font-family: "GolosText";
  box-sizing: border-box;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}

*::-webkit-scrollbar {
  width: 12px;
  @include b2k {
    width: 1.2rem;
  }
}

*::-webkit-scrollbar-track {
  background: var(--bg-dark-gray);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray);
  border-radius: 20px;
  border: 3px solid var(--bg-dark-black-4);
}

.ReactModal__Overlay {
  z-index: 1200;
  overflow: hidden;
  background-color: #00000085 !important;
}

.ReactModal__Body--open {
  // position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  max-width: 100%;
  background: #000;
}

ul,
ol {
  list-style: none;
  &.decimal {
    list-style: decimal;
    margin-left: 20px;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

button {
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

:where(.css-18iikkb).ant-carousel {
  line-height: 1;
}

.hidden {
  display: none;
}

.rotate0 {
  transform: rotate(0deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

.text-error {
  color: var(--red);
  padding-top: 12px;
  font-size: 14px;
}

.cus-checkbox {
  cursor: pointer;
}

.resize-no {
  resize: none;
}

@include sm {
  html {
    font-size: 2.55vw; // 10px on 320px
  }
}

@include b2k {
  html {
    font-size: 0.520833vw; // 10px on 1920px
  }
}

// range

.MuiSlider-track,
.MuiSlider-rail {
  color: var(--primary);
}
.MuiSlider-thumb {
  color: var(--primary);
  &:hover {
    box-shadow: 0px 0px 0px 8px rgba(118, 18, 25, 0.16) !important;
  }
}

.MuiSlider-valueLabelOpen {
  background-color: transparent !important;
}

// picker

.ant-picker-dropdown {
  z-index: 9999;
}

.required {
  color: var(--red);
}

input[type="radio"]:after {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--bg-dark-gray);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type="radio"]:checked:after {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--red);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.ant-carousel .slick-dots li {
  width: 8px;
  &.slick-active {
    width: 8px;
    height: 8px;
    button {
      background-color: var(--red);
    }
  }

  button {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 0.1);
  }
}

// available

// button:focus {
//   box-shadow: var(--box-shadow-focus);
// }

// label:has(> input[type="radio"]:focus) {
//   box-shadow: var(--box-shadow-focus);
// }

.ReactModal__Content:focus-visible,
textarea {
  border: none;
  outline: none;
}

.body__loader-overlay {
  overflow: hidden;
}

.Toastify {
  font-size: 16px;

  @include b2k {
    font-size: 1.6rem;

    &__toast {
      padding: 1rem;

      button svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &__toast-container {
      width: 32rem;
    }

    &__toast-icon {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  @include sm {
    font-size: 1.6rem;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.hide {
  position: absolute !important;
  // opacity: 0.5;
  opacity: 0;
  z-index: -10;
  // width: 0;
  // height: 0;
}

.bg-blur {
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(20px);
  overflow: hidden;
}

button[data-fancybox-toggle-fullscreen] {
  display: none;
}

img {
  user-select: none;
}

@include b2k {
  .spinner-container h3 {
    font-size: 1.8rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

@import url("./common/common.scss");
@import url("./user/user.scss");

// .floating-object {
//   position: fixed;
//   top: 100px; /* Initial position */
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: #ccc;
//   padding: 10px;
//   border-radius: 5px;
//   background-color: darkblue;
//   width: 100%;
// }

// .chat__send__send {
//   left: 50%;
//   transform: translateX(-50%);
// }
