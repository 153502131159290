@import "./../template.scss";
@import "../mixin";

.header {
  background: var(--bg-dark-black-2);
  box-shadow: var(--box-shadow-default);
  padding: 0 12px;

  .logo {
    height: 28px;
    width: auto;
  }

  .profile-select {
    min-width: 200px;
    button {
      border-radius: 20px;
      width: 100%;
    }
  }

  .balance__content {
    cursor: pointer;
  }

  &__content {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }

  &__segment {
    &__label {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }

  .button-filters {
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    background: var(--linear-171to71);
    box-shadow: var(--box-shadow-default-2);
    z-index: 6;
    svg {
      width: 18px;
      height: auto;
      opacity: 0.8;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  @media ((max-width: 1550px) and (min-width:950px)) {
    .logo {
      height: 20px;
      width: auto;
    }
  }

  @media ((max-width: 1515px) and (min-width:950px)) {
    &.authorized {
      .header__find {
        .find {
          width: 150px;
        }
      }
    }

    &__content {
      gap: 20px;
      .find {
        width: 230px;
        overflow: hidden;
      }
    }
  }

  @media ((max-width: 1335px) and (min-width:950px)) {
    $defaultSize: 12px;
    %defaultSizeSvg {
      svg {
        width: 14px;
      }
    }

    .profile-select {
      min-width: auto;
    }

    &__content {
      gap: 10px;
      padding-left: 0;
      padding-right: 0;

      .find {
        padding: 8px 16px;
        @extend %defaultSizeSvg;
        width: 200px;
        input {
          font-size: $defaultSize;
        }
      }

      .custom-button {
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 8px;
      }
    }

    .cus-select__container__title,
    .balance__content {
      padding: 4px 10px;
      font-size: 12px;
    }

    .balance__content {
      gap: 28px;

      img,
      button {
        width: 16px;
        font-size: 24px;
        height: 16px;
      }
    }
  }

  @media (max-width: 1150px) {
    &__content-two {
      display: none;
    }
    &__buttons {
      margin-left: auto;
    }
  }

  @media ((max-width: 1060px) and (min-width:950px)) {
    .chose-lang {
      width: 20px;
      .text {
        display: none;
      }

      &:hover ul {
        right: -50px;
      }
    }
  }

  @include lsm {
    .logo {
      height: 30px;
    }

    &__buttons {
      margin-left: auto;
      button {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 16px;
      }
    }

    .profile-select {
      min-width: auto;
    }

    &__content {
      height: 60px;
      gap: 20px;
    }

    &__lang {
      margin-left: auto;
    }

    &__segment {
      text-align: center;
      margin: auto;
    }

    .cus-select__container__title,
    .balance__content {
      padding: 4px 10px;
      font-size: 12px;
    }

    .balance__content {
      gap: 14px;

      img,
      button {
        width: 16px;
        font-size: 20px;
        height: 16px;
      }

      .mini-button {
        padding: 12px;
      }
    }

    .cus-select__container__title {
      gap: 8px;
    }

    .profile-select .cus-select__container__title .profile__btn {
      width: 26px;
      height: 26px;
      padding: 0;

      .avatar-rect__img-container {
        margin: auto;
      }

      img {
        width: 20px;
        height: 20px;
        min-height: 20px;
      }
    }
  }

  @media (max-width: 800px) {
    &__segment__label {
      gap: 4px;
      span {
        font-size: 12px;
      }
    }
  }

  @media ((max-width: 739px) and (min-width:640px)) {
    .header__content {
      gap: 8px;
    }
    .ant-segmented {
      &-group {
        gap: 4px;
        svg {
          width: 16px !important;
          height: auto;
        }
        .header__segment__label {
          & > span {
            font-size: 10px;
          }
        }
      }
      &-item {
        padding: 4px 8px;
      }
    }
  }

  @media (max-width: 950px) {
    .header__content-two {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
    }
    .header__segment.top-segment {
      display: none;
    }
  }

  // @media (max-width: 732px) {
  @media (max-width: 640px) {
    &__content {
      gap: 12px;
    }

    .logo {
      height: 20px;
    }

    &__content-two {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
    }
    &__segment.top-segment {
      display: none;
    }
  }

  @media (max-height: 400px) {
    .logo {
      height: 16px;
    }
  }

  @include sm {
    padding: 0;

    .logo {
      height: 1.6rem;
    }

    &__buttons {
      margin-left: inherit;
      gap: 0.8rem;
      button {
        padding: 0.8rem 2rem;
        font-size: 1.2rem;
        border-radius: 1.6rem;
      }
    }

    .balance__content {
      max-width: 8rem;
      .balance__value span {
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__content {
      padding: 0;
      padding: 0 0.8rem;
      padding-top: 1rem;
      gap: 0.2rem;
      &-two {
        padding-top: 0.4rem;
      }
    }

    .button-filters {
      width: 2rem;
      height: 2rem;
      svg {
        width: 1.4rem;
      }
    }

    &__segment {
      margin: 0;
      &__some {
        padding: 0;
      }

      .ant-segmented-group {
        gap: 0.8rem;
        padding: 0.4rem;
      }

      .ant-segmented-item {
        padding: 0.6rem 1rem;
      }

      &__label {
        gap: 0.4rem;
        svg {
          font-size: 1.4rem;
          width: 1.6rem;
        }
        span {
          font-size: 1.2rem;
        }
      }
    }

    .cus-select__container__title,
    .balance__content {
      padding: 0.4rem 0.8rem;
      font-size: 1.2rem;
    }

    .balance__content {
      gap: 1.4rem;

      img,
      button {
        width: 1.6rem;
        font-size: 2rem;
        height: 1.6rem;
      }

      .mini-button {
        padding: 1.2rem;
      }
    }

    .cus-select__container__title {
      gap: 0.6rem;
    }

    .profile-select .cus-select__container__title .profile__btn {
      width: 2.6rem;
      height: 2.6rem;

      img {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }

  @include b2k {
    .logo {
      height: 2.8rem;
    }

    &__content {
      padding: 0.8rem 2.2rem;
      gap: 2rem;
    }

    .profile-select {
      min-width: 20rem;
    }
  }
}

.russian {
  .header {
    .find {
      padding: 8px;
    }
  }

  @include sm {
    .logo {
      height: 1.6rem;
      width: auto;
    }

    .header__segment {
      .ant-segmented-group {
        gap: 0.4rem;
      }

      .ant-segmented-item {
        padding: 0.2rem 0.8rem;
      }

      &__label span {
        font-size: 1.2rem;
      }
    }
  }
}

.castellano,
.italiano,
.deutsch {
  .header__segment .ant-segmented-group {
    gap: 0.4rem;
  }
}
