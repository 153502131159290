.free-banner {
  margin-top: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  background: url("../../images/home/menu/coins.png") no-repeat, var(--bg-dark-black-4);
  background-position: 100% 100%;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 108px;
  }

  &__title {
    color: var(--white);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.72px;
  }

  &__p {
    color: var(--label-text-7);
    font-weight: 600;
    font-size: 14px;
    font-weight: 400;
  }

  &__button {
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    padding: 12px 24px;
    letter-spacing: -0.42px;
  }
}

.russian {
  .free-banner__button {
    span {
      font-size: 11.5px;
    }
  }
}
