.cus-card__container {
  gap: 16px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .cus-card {
    background-color: var(--bg-dark-gray);
    padding: 24px;
    border-radius: 16px;
    box-shadow: var(--box-shadow-default);

    &__first-row {
      padding: 4px 0 28px;
      display: flex;
      justify-content: space-between;
      gap: 4px;
    }

    &__full-id {
      display: flex;
      justify-content: space-between;
      gap: 8px;

      &__title {
        color: var(--label-text-4);
        letter-spacing: -0.24px;
        font-weight: 500;
      }
    }

    &__date {
      color: var(--label-text-6);
    }

    .direct-transfer {
      img {
        width: 16px;
      }
    }

    &__content__row {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      border-top: 1px solid var(--label-text-1);
      padding: 16px 0;

      &__title {
        color: var(--label-text-4);
      }
      &__value,
      &__title {
        display: flex;
        align-items: center;
        span {
          gap: 8px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @include sm {
    gap: 1.6rem;
    margin-top: 2.4rem;

    .cus-card {
      padding: 2.4rem;
      border-radius: 1.6rem;
      font-size: 1.4rem;

      &__first-row {
        padding: 0.4rem 0 2.8rem;
        gap: 0.4rem;
      }

      &__full-id {
        gap: 0.8rem;

        &__title {
          letter-spacing: -0.024rem;
        }
      }

      .direct-transfer {
        img {
          width: 1.6rem;
        }
      }

      &__content__row {
        gap: 0.4rem;
        padding: 1.6rem 0;

        &__value,
        &__title {
          span {
            gap: 0.8rem;
          }
        }
      }
    }
  }
}
