@import "../_mixin.scss";

.confirm-age-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  gap: 40px;
  z-index: 100;
  background-color: var(--black);
  background-image: url("../../images/confirm-age/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding-top: 8px;
    margin-top: auto;
    a {
      color: var(--white);
      cursor: pointer;
    }
  }

  &__title {
    color: var(--white);
    font-size: 32px;
    font-weight: 700;
  }

  &__text {
    color: var(--white);
    width: 500px;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      color: var(--white);
    }

    &__content {
      display: flex;
      border-radius: 16px;
      background: #1c1c1c;
      padding: 2px;
      box-shadow: var(--box-shadow-default);
      gap: 8px;

      &__content {
        min-height: 120px;
        width: 100%;
      }
    }
    button {
      width: 100%;
      padding: 16px;
    }
  }

  &__plug__text {
    width: 500px;
    text-align: center;
    color: var(--label-text-7);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.496px;
    button,
    a {
      display: inline;
      width: fit-content;
      padding: 0;
      background: transparent;
      outline: none;
      color: var(--primary-pale);
      font-weight: 600;
      line-height: 123%;
      letter-spacing: 0.496px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .custom-button__transparent {
    padding: 0;
  }

  &__category {
    background: var(--black);
    border-radius: 16px;
    border: 2px solid var(--black);
    color: var(--label-text-6);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    position: relative;
    cursor: pointer;
    width: 120px;
    height: 140px;

    span {
      position: absolute;
      bottom: 8px;
    }

    &:hover {
      color: var(--label-text-7);
    }

    img {
      width: auto;
      height: auto;
    }

    &__img {
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &.all-models {
      &.select {
        background: #ff1d7c10;
        background-image: radial-gradient(#ff1d7c, transparent);
        border-color: #ff1d7c;
      }
      img {
        position: relative;
        top: -12px;
      }
    }

    &.girls {
      &.select {
        background: #ff1de810;
        background-image: radial-gradient(#ff1de8, transparent);
        border-color: #ff1de8;
      }
    }

    &.guys {
      &.select {
        background: #1d41ff10;
        background-image: radial-gradient(#1d41ff, transparent);
        border-color: #1d41ff;
      }
      img {
        position: relative;
        top: -4px;
        left: 8px;
      }
    }

    &.trans {
      &.select {
        background: #cb444c10;
        background-image: radial-gradient(#cb444c, transparent);
        border-color: #cb444c;
      }
      img {
        position: relative;
        left: 8px;
      }
    }
  }

  &__plug {
    width: 708px;
    margin-top: auto;
    padding: 24px 44px;
    border-radius: 32px 32px 0px 0px;
    background: var(--bg-white-03);
    box-shadow: var(--box-shadow-default);
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;

    p {
      width: 100%;
    }

    &__text {
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.496px;

      &__accent {
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
        font-family: Golos;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.496px;
      }
    }
  }

  @include lsm {
    %contentWidth {
      width: 90%;
      min-width: 320px;
    }

    &__text {
      font-size: 14px;
      text-align: center;
      width: 340px;
    }

    &__categories {
      width: 340px;

      .confirm-age-modal__plug__text {
        width: 340px;
      }
      &__title {
        font-size: 12px;
      }
    }

    &__category {
      width: 80px;
      height: 104px;
      font-size: 10px;
      img {
        transform: scale(0.75) translateY(-20px);
      }
    }

    &__plug {
      @extend %contentWidth;
    }
  }

  @include smLsp {
    overflow: auto;
    .confirm-age-modal__plug {
      min-height: 150px;
    }
  }

  @include sm {
    &__text {
      font-size: 1.4rem;
      text-align: center;
      width: 34rem;
    }

    &__categories {
      width: 34rem;

      .custom-button {
        font-size: 1.4rem;
      }

      .confirm-age-modal__plug__text {
        width: 34rem;
      }
      &__title {
        font-size: 1.2rem;
      }
    }

    &__category {
      width: 8rem;
      height: 10.4rem;
      font-size: 1rem;

      img {
        transform: scale(1) translateY(0px);
      }

      &.all-models {
        img {
          width: 7rem;
        }
        &.select {
          img {
            width: 8rem;
          }
        }
      }

      &.girls {
        img {
          width: 4rem;
        }
        &.select {
          img {
            width: 4.9rem;
          }
        }
      }

      &.guys {
        img {
          width: 7rem;
        }
        &.select {
          img {
            width: 8rem;
          }
        }
      }

      &.trans {
        img {
          width: 5rem;
        }
        &.select {
          img {
            width: 6rem;
          }
        }
      }
    }

    a {
      font-size: 1.4rem;
    }

    &__plug {
      padding: 1.2rem 2.4rem;

      &__text {
        font-size: 1.2rem;

        &__accent {
          font-size: 1.4rem;
        }
      }
    }
  }

  @include b2k {
    gap: 4rem;

    &__content {
      gap: 2.4rem;
      padding-top: 0.8rem;

      a {
        font-size: 1.4rem;
      }
    }

    &__title {
      font-size: 3.2rem;
    }

    &__text {
      width: 50rem;
      font-size: 1.4rem;
    }

    &__categories {
      gap: 2rem;

      &__title {
        font-size: 1.4rem;
      }

      button {
        font-size: 1.4rem;
      }

      &__content {
        border-radius: 1.6rem;
        padding: 0.2rem;
        gap: 0.8rem;

        .all-models img {
          width: 11rem;
        }
        .girls img {
          width: 7.6rem;
        }

        .guys img {
          width: 12rem;
        }

        .trans img {
          width: 9rem;
        }
      }
    }

    &__plug__text {
      width: 50rem;
      font-size: 1.2rem;
    }

    &__category {
      border-radius: 1.6rem;
      padding: 1.2rem;
      width: 12rem;
      height: 14rem;

      span {
        bottom: 0.8rem;
        font-size: 1.4rem;
      }

      &__img {
        width: 12rem;
        height: 12rem;
      }

      &.all-models {
        img {
          top: -1.2rem;
        }
      }

      &.guys {
        img {
          top: -0.4rem;
          left: 0.8rem;
        }
      }

      &.trans {
        img {
          left: 0.8rem;
        }
      }
    }

    &__plug {
      width: 70.8rem;
      padding: 2.4rem 4.4rem;
      gap: 1.6rem;

      &__text {
        font-size: 1.2rem;
        letter-spacing: 0.0496rem;

        &__accent {
          font-size: 1.4rem;
        }
      }
    }
  }
}
