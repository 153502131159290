@import "../mixin";

.custom-button {
  display: flex;
  gap: 12px;
  background-color: var(--bg-dark-black);
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: 500;
  font-size: 14px;
  padding: 12px 24px;
  height: fit-content;
  cursor: pointer;

  &-spinner {
    display: block;
    position: relative;
    scale: 0.5;
    top: -20px;
    left: -40px;

    .spinner {
      display: contents;
    }
  }

  &:hover {
    filter: contrast(150%);
  }

  &.full-width {
    width: 100%;
  }

  &__red {
    background-color: var(--primary);
  }

  &__green {
    background-color: var(--green);
  }

  &__black {
    background-color: var(--black);
  }

  &__transparent {
    background-color: transparent;
  }

  img {
    max-width: 30px;
    max-height: 30px;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    &:hover {
      opacity: 0.6;
      background-color: var(--primary);
      filter: contrast(100%);
    }
  }

  &.loading {
    position: relative;
    color: var(--primary) !important;
    opacity: 1;
    background-color: var(--primary);
    .spinner {
      position: absolute;
      width: 40px;
      height: 40px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
    }
  }

  @include sm {
    gap: 1.2rem;
    border-radius: 0.8rem;
    font-size: 2.4rem;
    padding: 1.2rem 3.6rem;

    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  @include b2k {
    gap: 1.2rem;
    border-radius: 1.6rem;
    font-size: 1.4rem;
    padding: 1.2rem 2.4rem;

    img {
      max-width: 3rem;
      max-height: 3rem;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    .rotate0,
    .rotate90,
    .rotate180,
    .rotate270 {
      width: 1.2rem;
      height: 1.2rem;
    }

    &.loading {
      .spinner {
        position: absolute;
        width: 4rem;
        height: 4rem;
        left: calc(50% - 3rem);
        top: calc(50% - 3rem);
      }
    }
  }
}
