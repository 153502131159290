.balance {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    color: var(--label-text-4);
    font-size: 14px;
    font-weight: 500;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 16px;
    padding: 8px 16px;
    background: var(--gradient-balance-block);
    max-width: 330px;

    .mini-button {
      padding: 12px;
      width: 28px;
      height: 28px;
      font-size: 28px;
      font-weight: 900;
      border-radius: 8px;
    }
  }

  &__value {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  @media (max-width: 1625px) {
    &__content {
      .mini-button,
      .tk {
        display: none;
      }
    }
  }

  @include sm {
    gap: 0.4rem;

    &__title {
      font-size: 1.4rem;
    }

    &__content {
      gap: 0.4rem;
      border-radius: 1.6rem;
      padding: 0.8rem 1.6rem;
      max-width: 33rem;

      .mini-button {
        padding: 1.2rem;
        width: 2.8rem;
        height: 2.8rem;
        font-size: 2.8rem;
        border-radius: 0.8rem;
      }
    }

    &__value {
      gap: 0.8rem;
    }
  }

  @include b2k {
    gap: 0.4rem;

    &__title {
      font-size: 1.4rem;
    }

    &__content {
      gap: 4rem;
      border-radius: 1.6rem;
      padding: 0.8rem 1.6rem;
      max-width: 33rem;

      .mini-button {
        padding: 1.2rem;
        width: 2.8rem;
        height: 2.8rem;
        font-size: 2.8rem;
        border-radius: 0.75rem;
      }
    }

    &__value {
      gap: 0.8rem;
      font-size: 1.6rem;

      img {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}
