.home__unheader {
  background: var(--bg-dark-black-3);

  &__content {
    padding: 6px 12px;
    display: flex;
    color: var(--label-text-6);
    justify-content: space-between;
  }

  &__find {
    .find {
      min-height: 49px;
    }
  }

  &__filters {
    width: 232px;
    padding: 14px 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-dark-gray);
    box-shadow: var(--box-shadow-default);
    cursor: pointer;
    position: relative;
    z-index: 1;

    div {
      background: transparent;
      color: var(--white);
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &__nav {
    display: flex;
    align-items: flex-end;
  }

  &__nav.mobile-header {
    .ant-carousel {
      user-select: none;
      .slick-list {
        width: calc(100svw - 50px);
      }
      .slick-track {
        display: flex;
        gap: 8px;
        // width: 90vw !important;

        .item {
          width: 150px !important;
          display: flex !important;
          padding: 14px 20px;
          gap: 4px;
          align-items: center;
        }
      }
    }
  }

  &__categories {
    display: flex;
    gap: 16px;

    &__title {
      color: var(--label-text-6);
    }
  }

  &__category {
    padding: 0 30px;
    padding-bottom: 14px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &__cross {
      position: absolute;
      display: none;
      right: 0;
      background: var(--bg-dark-black-5);
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--white);
        border-bottom: 2px solid var(--primary-opacity05);

        svg {
          path {
            fill: var(--yellow);
            fill-opacity: 0.6;
          }
        }
      }
    }

    &.selected {
      border-bottom: 2px solid var(--primary);

      .home__unheader__categories__title {
        color: var(--white);
      }

      svg {
        path {
          fill: var(--yellow);
          fill-opacity: 1;
        }
      }

      .home__unheader__category__cross {
        display: flex;

        svg {
          path {
            fill: var(--label-text-6);
            fill-opacity: 0.8;
          }
        }
      }
    }
  }

  .button-find {
    border-radius: 4px;
    height: fit-content;
    margin: auto 0;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    background: var(--linear-171to71);
    box-shadow: var(--box-shadow-default-2);

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include lg {
    &__category {
      padding: 16px 24px;
      padding-bottom: 14px;
    }

    &__categories {
      gap: 12px;
    }
  }

  @include l1220 {
    %defaultSvg {
      svg {
        width: 14px;
        display: block;
      }
    }

    $defaultSize: 12px;

    &__filters {
      width: auto;
      border-radius: 16px;
      padding: 4px 12px;
      & > div {
        gap: 6px;
        @extend %defaultSvg;
        font-size: $defaultSize;
      }
      svg {
        display: none;
      }
    }

    ul {
      gap: 16px;

      li {
        font-size: 14px;

        .home__unheader__category {
          padding: 12px;
        }
      }
    }

    .cus-select {
      @extend %defaultSvg;

      &__options__li {
        padding: 12px;

        &__icon {
          width: 20px;
          height: 20px;
        }
      }

      span {
        font-size: $defaultSize;
      }
    }
  }

  @include lsm {
    &__nav {
      overflow: hidden;
      margin-right: 8px;
    }
  }

  @include sm {
    &__content {
      padding: 0.8rem;
    }

    &__find {
      width: calc(100% - 4rem);
      .find {
        width: auto;
      }
    }

    &__category {
      svg {
        font-size: 1.4rem;
      }
      &__title {
        font-size: 1.2rem;
      }
    }

    &__nav {
      .ant-carousel {
        .slick-track {
          gap: 4px;
        }
      }
    }

    &__nav.mobile-header .ant-carousel .slick-track .item {
      padding: 0.8rem 1.2rem;
      font-size: 1.1rem;
    }

    // .slick-slide {
    //   width: 15rem !important;
    // }

    .button-find {
      svg {
        width: 18px;
        height: auto;
      }
    }
  }

  @include b2k {
    &__content {
      padding: 0.6rem 2.4rem;
    }

    &__filters {
      width: 21.2rem;
      padding: 0.7rem 1.2rem;
      border-radius: 1.6rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      div {
        font-size: 1.4rem;
        gap: 1.2rem;
      }
    }

    &__categories {
      gap: 1.6rem;
    }

    &__category {
      padding: 0 3rem;
      padding-bottom: 1.4rem;
      font-size: 1.4rem;
      gap: 0.6rem;
      border-bottom: 0.2rem solid transparent;
      svg {
        width: 1.6rem;
        height: 2.6rem;
      }

      &__cross {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 0.02rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      &:hover,
      &.selected {
        border-width: 0.2rem;
      }
    }
  }
}

.russian {
  .home__unheader {
    @media (max-width: 1000px) {
      .cus-select__title__text {
        display: none;
      }
    }
    @media (max-width: 1310px) {
      &__filters {
        width: auto;
        gap: 4px;
        & > div {
          font-size: "12px";
        }
      }

      ul {
        gap: 4px;
        li {
          font-size: "12px";
          .home__unheader__category {
            padding: 12px;
          }
        }
      }

      .cus-select {
        &__options__li {
          padding: 12px;
        }

        span {
          font-size: "12px";
        }
      }
    }
  }
}

.deutsch,
.castellano,
.francais,
.italiano,
.portugues {
  .home__unheader {
    &__nav.mobile-header .ant-carousel .slick-track .item {
      padding: 14px 12px;
    }

    @include lg {
      &__category {
        padding: 14px 12px;
      }
    }
  }
}
