@import "../_mixin.scss";

.custom-input {
  position: relative;
  display: flex;
  flex-direction: column;

  &__gray-warning {
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-text);
  }

  &__title {
    font-size: 14px;
    color: var(--label-text-4);
  }

  &__container {
    margin-top: 8px;
    background-color: var(--bg-dark-gray);
    border-radius: 16px;
    position: relative;

    &.error {
      border: 2px solid var(--red);
    }
  }

  &.error-container {
    & ~ button {
      &:hover {
        cursor: default;
        opacity: 0.6;
      }
      opacity: 0.6;
      filter: contrast(100%);
    }
  }

  input,
  textarea {
    padding: 16px 24px;
    background-color: var(--bg-dark-gray);
    color: var(--white);
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 16px;
    &.password,
    &.icon {
      width: calc(100% - 24px);
    }
  }
  input[type="password"] {
    width: calc(100% - 24px);
  }

  .password__hide__btn {
    position: absolute;
    left: auto;
    display: flex;
    align-items: center;
    top: 0;
    right: 20px;
    width: 20px;
    padding: 0;
    height: 100%;
    background-color: transparent;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: var(--label-text-6);
        fill-opacity: 1;
      }
    }
  }

  img {
    position: absolute;
    left: 8px;
    top: 6px;
    width: 24px;
  }

  .custom-input__container.plug-text-1 {
    input {
      position: relative;
      left: 2px;
    }
  }

  .plug-text {
    position: relative;
    z-index: 1;
    left: 24px;
  }

  @include b2k {
    &__gray-warning {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &__title {
      font-size: 1.4rem;
    }

    &__container {
      margin-top: 0.8rem;
      border-radius: 1.6rem;

      &.error {
        border-width: 0.02rem;
      }
    }

    input {
      padding: 1.6rem 2.4rem;
      font-size: 1.4rem;
      border-radius: 1.6rem;
      &.password,
      &.icon {
        width: calc(100% - 2.4rem);
      }
    }
    input[type="password"] {
      width: calc(100% - 2.4rem);
    }

    .password__hide__btn {
      right: 2rem;
      width: 2rem;
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    img {
      left: 0.8rem;
      top: 0.6rem;
      width: 2.4rem;
    }

    .custom-input__container.plug-text-1 {
      input {
        left: 0.02rem;
      }
    }

    .plug-text {
      left: 2.4rem;
    }
  }
}
