.new-avatar {
  %circleVideo {
    width: 164px;
    height: 164px;
    border-radius: 50%;
  }

  &__block.capture-take {
    %circleVideo {
      width: 210px;
      height: 210px;
    }
  }

  &__camera-setting {
    margin-left: auto;
    &:hover {
      filter: contrast(1);
    }
  }

  display: flex;
  flex-direction: column;
  gap: 32px;

  &__canvas {
    display: none;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      opacity: 0.2;
    }

    &:hover {
      svg {
        opacity: 0.8;
      }
    }
  }

  &__description {
    display: flex;
    align-items: center;
    gap: 8px;

    &__text {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.32px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    display: flex;
    gap: 24px;
  }
  &__block {
    width: 100%;
    background: var(--bg-opacity-red-06);
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: 2px dashed var(--red);

    &.capture-take {
      border: none;
      min-height: 300px;
    }

    &.take-photo {
      background-color: transparent;

      .new-avatar__block__booth {
        padding: 12px;
        border-radius: 50%;
        border: 8px solid var(--red);
      }
    }

    &__img {
      @extend %circleVideo;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }

      svg {
        z-index: 1;
      }

      &:hover {
        cursor: pointer;
        svg path {
          fill: var(--white);
        }
      }

      &__circle-1,
      &__circle-2 {
        border-radius: 50%;
        position: absolute;
        opacity: 0.2;
      }

      &__circle-1 {
        @extend %circleVideo;
        background-color: var(--red);
      }

      &__circle-2 {
        width: 120px;
        height: 120px;
        background-color: var(--primary-pale);
      }
    }

    &__text {
      font-size: 14px;
      font-weight: 600;
    }

    .custom-button {
      background: var(--linear-opacity-red-5);
    }

    &__booth {
      position: relative;

      &__timer {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 8px);
        font-size: 40px;
        text-shadow: 4px 4px 4px #000;
        text-shadow: 4px 4px 4px #000;
      }

      img,
      video {
        @extend %circleVideo;
      }
    }

    &__buttons {
      display: flex;
      gap: 16px;
      justify-content: center;

      .custom-button__default {
        background: var(--black);
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    button span {
      font-weight: 600;
      font-weight: 600;
      font-size: 14px;
    }
  }

  @include sm {
    %circleVideo {
      width: 12rem;
      height: 12rem;
    }

    &__block.capture-take {
      %circleVideo {
        width: 24rem;
        height: 24rem;
      }
    }

    gap: 3.2rem;

    &__back {
      gap: 0.8rem;
      font-size: 1.4rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &__description {
      gap: 0.8rem;

      &__text {
        font-size: 1.6rem;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &__content {
      gap: 1rem;
    }
    &__block {
      border-radius: 1.6rem;
      padding: 1rem;
      gap: 1.6rem;

      &__img {
        @extend %circleVideo;

        &__circle-2 {
          width: 8rem;
          height: 8rem;
        }
      }

      &__text {
        font-size: 1.2rem;
      }

      &__booth {
        &__timer {
          top: -2rem;
          top: calc(50% - 1.8rem);
          left: calc(50% - 1rem);
          font-size: 4rem;
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        button {
          font-size: 1rem;
        }
      }
    }

    &__buttons {
      gap: 1rem;

      button span {
        font-size: 1.4rem;
      }
    }
  }

  @include b2k {
    gap: 3.2rem;

    &__back {
      gap: 0.8rem;
      font-size: 1.4rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &__description {
      gap: 0.8rem;

      &__text {
        font-size: 1.6rem;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &__content {
      gap: 1rem;
    }
    &__block {
      border-radius: 1.6rem;
      padding: 1rem;
      gap: 1.6rem;

      &__img {
        width: 164px;
        height: 164px;

        &__circle-2 {
          width: 8rem;
          height: 8rem;
        }
      }

      &__text {
        font-size: 1.4rem;
      }

      &__booth {
        &__timer {
          top: -2rem;
          top: calc(50% - 1.8rem);
          left: calc(50% - 1rem);
          font-size: 4rem;
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        button {
          font-size: 1rem;
        }
      }
    }

    &__buttons {
      gap: 1rem;

      button span {
        font-size: 1.4rem;
      }
    }
  }
}
