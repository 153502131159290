.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-clear {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-picker-dropdown .ant-picker-cell-disabled {
  opacity: 0.6;
}

.cus-date-picker {
  background-color: var(--bg-dark-gray);
  border: none;
  cursor: pointer;

  svg {
    fill: var(--label-text-6);

    &[data-icon="close-circle"] {
      background-color: var(--bg-dark-gray);
    }
  }

  .ant-picker-input > input {
    color: var(--white);

    &:focus {
      border: none;
    }

    &::placeholder {
      color: var(--label-text-6);
    }
  }
}

.popup_cus-date-picker {
  .ant-picker-panel-container {
    background-color: var(--bg-dark-gray);

    * {
      color: var(--white);
      &:hover {
        color: var(--red) !important;
      }
    }

    thead {
      th {
        color: var(--label-text-6);
        &:hover {
          color: var(--label-text-6) !important;
        }
      }
    }

    .ant-picker-cell-inner {
      &::before {
        border: 1px solid var(--red) !important;
      }
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: var(--red);

      &:hover {
        color: var(--label-text-6) !important;
      }
    }
  }
}

@include b2k {
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker .ant-picker-input > input,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-header-view button,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown,
  :where(.css-18iikkb).ant-picker .ant-picker-input > input,
  .ant-picker-month-btn,
  .cus-date-picker .ant-picker-input > input,
  :where(.css-18iikkb).ant-picker-dropdown,
  :where(.css-18iikkb)[class^="ant-picker"],
  :where(.css-18iikkb)[class*=" ant-picker"] {
    font-size: 1.4rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-prev-icon::before,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-next-icon::before,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown
    .ant-picker-super-prev-icon::before,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown
    .ant-picker-super-next-icon::before {
    width: 0.7rem !important;
    height: 0.7rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-content
    th,
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    width: 3.2rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-decade-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-year-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-quarter-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-month-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-week-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-date-panel,
  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-time-panel,
  .ant-picker-dropdown .ant-picker-decade-panel,
  .ant-picker-dropdown .ant-picker-year-panel,
  .ant-picker-dropdown .ant-picker-quarter-panel,
  .ant-picker-dropdown .ant-picker-month-panel,
  .ant-picker-dropdown .ant-picker-week-panel,
  .ant-picker-dropdown .ant-picker-date-panel,
  .ant-picker-dropdown .ant-picker-time-panel {
    width: 24.8rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-body {
    padding: 0.9rem 1.8rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown
    .ant-picker-panel
    .ant-picker-footer {
    padding-bottom: 1.8rem !important;
  }

  :where(.css-dev-only-do-not-override-18iikkb).ant-picker-dropdown .ant-picker-header {
    padding: 0.6rem 1.6rem !important;
  }
}
