.profile-modal .setting__common .country ul.cus-select__options__region {
  max-height: 100%;
}
.cus-select__options__region {
  .region__title {
    margin: 20px 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 900;
  }
}
