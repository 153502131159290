.setting {
  &__content {
    margin-top: 24px;
  }

  &__common {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 24px;

    .country ul {
      max-height: 250px;
      overflow: auto;
    }

    & > div {
      justify-content: end;
      font-size: 14px;
      line-height: 100%;
    }

    .container-date-picker {
      grid-column: 1/3;
    }

    .ant-picker-input input {
      font-size: 14px;
      line-height: 100%;
    }

    .cus-select {
      margin-top: 8px;
    }

    .cus-select__container__title {
      padding: 16px 24px;
    }
  }

  &__documents {
    background: var(--linear-opacity-red2);
    padding: 16px;
    border-radius: 20px;
    margin-top: 24px;

    .custom-attach {
      margin-top: 16px;
    }

    &__chapter {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    &__warn {
      @extend %warn-char;
    }

    &__text {
      &__title {
        letter-spacing: -0.32px;
        font-size: 16px;
        font-weight: 600;
      }
      &__p {
        margin-top: 6px;
        color: var(--label-text-6);
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  &__password {
    margin-top: 28px;

    &__title {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.32px;
    }

    &__fields {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .custom-button {
      margin-top: 32px;
      font-size: 14px;
      font-weight: 600;
      padding: 14px;
    }
  }

  @media (max-height: 850px) {
    &__content,
    &__documents,
    &__common,
    &__password,
    &__password__fields {
      margin-top: 16px;
    }

    &__common {
      gap: 12px 16px;
    }

    &__documents {
      .custom-attach {
        margin-top: 8px;
      }
    }

    &__password .custom-button {
      margin-top: 20px;
    }
  }
  @media (max-height: 750px) {
    &__content,
    &__documents,
    &__common,
    &__password,
    &__password__fields {
      margin-top: 12px;
    }

    &__common {
      gap: 12px;
    }

    &__password .custom-button {
      margin-top: 16px;
    }
  }

  @include lsm {
    &__common,
    &__password__fields {
      grid-template-columns: 1fr;
    }

    &__common .container-date-picker {
      grid-column: auto;
    }
  }

  @include sm {
    &__content {
      margin-top: 2.4rem;
      h4 {
        font-size: 1.6rem;
      }
      .custom-input__title,
      .cus-select-container label {
        font-size: 1.2rem;
      }
    }

    &__common {
      gap: 1.6rem;
      margin-top: 2.4rem;

      & > div {
        font-size: 1.4rem;
      }

      .ant-picker-input input {
        font-size: 1.4rem;
      }

      .cus-select {
        margin-top: 0.8rem;
      }

      .cus-select__container__title {
        padding: 0.8rem 1.6rem;
      }
    }

    &__documents {
      padding: 0rem;
      margin-top: 2.4rem;
      border-radius: 1rem;

      .custom-attach {
        margin-top: 1.6rem;
        & > label {
          font-size: 1.2rem;
        }
      }

      &__chapter {
        gap: 1.6rem;
        padding: 1.6rem;
      }

      &__text {
        &__title {
          font-size: 1.6rem;
        }
        &__p {
          margin-top: 0.4rem;
          font-size: 1.2rem;
        }
      }
    }

    &__password {
      margin-top: 2.8rem;

      &__title {
        font-size: 1.6rem;
      }

      &__fields {
        margin-top: 2.4rem;
      }

      .custom-button {
        margin-top: 3.2rem;
        font-size: 1.4rem;
        padding: 1.4rem;
      }
    }
  }

  @include b2k {
    &__content {
      margin-top: 2.4rem;

      h4 {
        font-size: 1.6rem;
      }
    }

    &__common {
      gap: 2.4rem;
      margin-top: 2.4rem;

      .country ul {
        max-height: 25rem;
      }

      & > div {
        font-size: 1.4rem;
      }

      .ant-picker-input input {
        font-size: 1.4rem;
      }

      .cus-select {
        margin-top: 0.8rem;
      }

      .cus-select__container__title {
        padding: 1.5rem 2.4rem;

        input {
          font-size: 1.6rem;
        }
      }
    }

    &__documents {
      padding: 1.6rem;
      border-radius: 2rem;
      margin-top: 2.4rem;

      .custom-attach {
        margin-top: 1.6rem;
      }

      &__chapter {
        gap: 1.6rem;
      }

      &__text {
        &__title {
          letter-spacing: -0.64px;
          font-size: 1.6rem;
        }
        &__p {
          margin-top: 0.6rem;
          font-size: 1.2rem;
        }
      }
    }

    &__password {
      margin-top: 2.8rem;

      &__title {
        font-size: 1.6rem;
        letter-spacing: -0.64px;
      }

      &__fields {
        margin-top: 2.4rem;
        gap: 1.6rem;
      }

      .custom-button {
        margin-top: 3.2rem;
        font-size: 1.4rem;
        padding: 1.4rem;
      }
    }
  }
}
