.custom-attach {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__placeholder {
    color: var(--label-text-4);
  }

  &__file-name {
    color: var(--white);
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: var(--label-text-4);
  }

  &__hide-file {
    display: none;
  }

  &__virtual-attach {
    background-color: var(--bg-dark-gray);
    box-shadow: var(--box-shadow-default);
    padding: 16px 24px;
    border-radius: 16px;
    display: flex;
    cursor: pointer;

    .clip-container {
      display: flex;
      margin: auto;
      margin-left: auto;
      margin-right: 0;
      background-color: transparent;
    }
  }
}
