.statistic {
  .cus-select {
    padding: 0;
    &__container__title {
      padding: 16px 24px;
    }
  }

  &__filter {
    &__row {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;

      &__two {
        grid-template-columns: 1fr 1fr;
      }

      .container-date-picker {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__label {
          color: var(--label-text-4);
          font-size: 14px;
          font-weight: 500;
        }

        .ant-picker {
          padding: 16px 24px;
          border-radius: 16px;
        }
      }

      .custom-button {
        margin-top: auto;
        line-height: 100%;
        padding: 16px 24px;
      }

      .select-container {
        label {
          color: var(--label-text-4);
          font-size: 14px;
          font-weight: 500;
        }

        .cus-select {
          margin-top: 8px;
        }
      }
    }

    .statistic__time {
      display: flex;
      margin-top: 20px;
      align-items: center;
      gap: 8px;

      &__title {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
      }

      &__date {
        color: var(--label-text-6);
        letter-spacing: -0.28px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__content {
    min-height: 300px;
    &.loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // EMPTY

  &__empty {
    text-align: center;

    &__circle {
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: var(--primary-pale);
      opacity: 0.25;
      border-radius: 50%;
      top: calc(50% - 100px);
      left: calc(50% - 100px);
    }

    &__circle-gradient {
      position: absolute;
      left: calc(50% - 168px);
      top: calc(50% - 168px);
      width: 336px;
      height: 336px;
      border-radius: 336px;
      opacity: 0.2;
      background: var(--primary-pale);
      filter: blur(36px);
    }

    &__img {
      position: relative;
      width: fit-content;
      margin: auto;
      margin-top: 60px;
      min-width: 250px;
      min-height: 250px;
      img {
        position: relative;
      }
    }

    &__p {
      margin-top: 32px;
      font-size: 12px;
      color: var(--label-text-6);
    }
  }

  // table

  .avatar__btn {
    display: flex;
    width: 35px;
    height: 35px;
    transition: 1s;
  }

  .cus-table {
    margin-top: 8px;

    .ant-table-cell {
      .service {
        svg path {
          fill: var(--yellow);
        }
      }

      span {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .amount {
        span {
          line-height: 14px;
        }
        &__value {
          font-weight: 600;
          font-size: 14px;
          color: var(--white);
        }
      }
    }
  }

  .pagination__content {
    padding: 20px 20px 0;
    .pagination__pages {
      & > div,
      .page {
        width: 48px;
        height: 48px;
      }
    }
  }

  .transition-type {
    width: fit-content;
    padding: 6px 12px;
    border-radius: 16px;
    background-color: var(--transaction-spend);
  }

  .direct-transfer {
    background-color: var(--transaction-refund-plus);
  }

  @media (max-height: 850px) {
    &__filter {
      .statistic__time {
        margin-top: 8px;
      }
    }

    .cus-table .ant-table .cus-table__row td {
      padding: 12px 8px;
    }

    .pagination__content {
      padding-top: 8px;

      .pagination__pages {
        & > div,
        .page {
          width: 40px;
          height: 40px;
        }
      }
    }

    &__empty {
      &__circle {
        width: 150px;
        height: 150px;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
      }

      &__circle-gradient {
        left: calc(50% - 150px);
        top: calc(50% - 150px);
        width: 300px;
        height: 300px;
        border-radius: 50%;
        opacity: 0.2;
      }

      &__img {
        margin-top: 40px;
        min-width: 200px;
        min-height: 200px;
        img {
          width: 200px;
          position: relative;
        }
      }

      &__p {
        margin-top: 16px;
      }
    }
  }

  @media (max-height: 750px) {
    &__content {
      min-height: 250px;
    }

    .cus-table .ant-table .cus-table__row td {
      padding: 8px;
    }

    .pagination__content .pagination__pages {
      & > div,
      .page {
        width: 28px;
        height: 28px;
        font-size: 12px;
      }
    }

    &__empty {
      &__circle {
        width: 120px;
        height: 120px;
        top: calc(50% - 60px);
        left: calc(50% - 60px);
      }

      &__circle-gradient {
        left: calc(50% - 120px);
        top: calc(50% - 120px);
        width: 240px;
        height: 240px;
      }

      &__img {
        margin-top: 32px;
        min-width: 150px;
        min-height: 150px;
        img {
          width: 160px;
          position: relative;
        }
      }

      &__p {
        margin-top: 16px;
      }
    }
  }

  @include lsm {
    &__filter__row .cus-select__container__title {
      padding: 16px 8px;
      gap: 4px;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include sm {
    &__filter {
      &__row {
        margin-top: 1.6rem;
        gap: 1.6rem;
        grid-template-columns: 1fr;

        .cus-select {
          &__container__title {
            padding: 1.6rem 0.8rem;
          }
        }

        .container-date-picker {
          gap: 0.8rem;

          &__label {
            font-size: 1rem;
          }

          .ant-picker {
            padding: 1rem 2.4rem;
            border-radius: 1.6rem;
          }
        }

        .custom-button {
          line-height: 2.2rem;
          padding: 1rem 2.4rem;
          border-radius: 1.4rem;
        }

        .ant-picker-input input {
          font-size: 1.4rem;
        }

        .select-container {
          label {
            font-size: 1.4rem;
          }

          .cus-select {
            margin-top: 0.8rem;
            span {
              &::placeholder {
                font-size: 1.4rem;
              }
              font-size: 1.4rem;
            }
          }
        }
      }

      .custom-button {
        font-size: 1.4rem;
      }

      .container-date-picker__label {
        font-size: 1.4rem;
      }

      .statistic__time {
        margin-top: 2rem;
        gap: 0.8rem;

        &__title {
          font-size: 1.6rem;
          letter-spacing: -0.032rem;
        }

        &__date {
          letter-spacing: -0.028rem;
          font-size: 1.4rem;
        }
      }
    }

    // EMPTY

    &__empty {
      &__circle {
        width: 20rem;
        height: 20rem;
        top: calc(50% - 10rem);
        left: calc(50% - 10rem);
      }

      &__circle-gradient {
        left: calc(50% - 16.8rem);
        top: calc(50% - 16.8rem);
        width: 33.6rem;
        height: 33.6rem;
        border-radius: 33.6rem;
      }

      &__img {
        margin-top: 6rem;
        min-width: 25rem;
        min-height: 25rem;

        img {
          width: 16rem;
        }
      }

      &__p {
        margin-top: 3.2rem;
        font-size: 1.2rem;
      }
    }

    // table

    .cus-table {
      margin-top: 0.8rem;

      .ant-table-cell {
        span {
          gap: 0.8rem;
        }

        .amount {
          span {
            line-height: 1.4rem;
          }
          &__value {
            font-size: 1.4rem;
          }
        }
      }
    }

    .pagination__content {
      padding: 2rem 2rem 0;
    }

    .transition-type {
      padding: 0.6rem;
      border-radius: 1.6rem;

      svg {
        width: 1.6rem;
        height: 100%;
      }
    }
  }

  @include b2k {
    .cus-select {
      &__container__title {
        padding: 1.6rem 2.4rem;
      }
    }

    &__filter {
      &__row {
        margin-top: 1.6rem;
        gap: 1.6rem;

        .container-date-picker {
          gap: 0.8rem;

          &__label {
            font-size: 1.4rem;
          }

          .ant-picker {
            padding: 1.6rem 2.4rem;
            border-radius: 1.6rem;
          }
        }

        .custom-button {
          padding: 1.6rem 2.4rem;
        }

        .select-container {
          label {
            font-size: 1.4rem;
          }

          .cus-select {
            margin-top: 0.8rem;
          }
        }
      }

      .statistic__time {
        margin-top: 2rem;
        gap: 0.8rem;

        svg {
          width: 1.9rem;
          height: 1.9rem;
        }

        &__title {
          font-size: 1.6rem;
          letter-spacing: -0.64px;
        }

        &__date {
          letter-spacing: -0.56px;
          font-size: 1.4rem;
        }
      }
    }

    &__content {
      min-height: 30rem;
    }

    // EMPTY

    &__empty {
      &__circle {
        width: 20rem;
        height: 20rem;
        top: calc(50% - 10rem);
        left: calc(50% - 10rem);
      }

      &__circle-gradient {
        left: calc(50% - 16.8rem);
        top: calc(50% - 16.8rem);
        width: 33.6rem;
        height: 33.6rem;
        border-radius: 33.6rem;
        filter: blur(3.6rem);
      }

      &__img {
        margin-top: 6rem;
        min-width: 25rem;
        min-height: 25rem;

        img {
          width: 24.4rem;
          height: 24.4rem;
        }
      }

      &__p {
        margin-top: 3.2rem;
        font-size: 1.2rem;
      }
    }

    // table
    .avatar__btn {
      width: 3.5rem;
      height: 3.5rem;
      &:hover {
        width: 7rem;
        height: 7rem;
      }
    }

    .cus-table {
      margin-top: 0.8rem;

      .ant-table-cell {
        span {
          gap: 0.8rem;
        }

        .transition-type {
          img {
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        .amount {
          span {
            line-height: 1.4rem;
          }
          &__value {
            font-size: 1.4rem;
          }
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }

    .pagination__content {
      padding: 2rem 2rem 0;
      .pagination__pages {
        & > div,
        .page {
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }

    .transition-type {
      padding: 0.6rem;
      border-radius: 1.6rem;
    }
  }
}
