.footer {
  background: var(--bg-dark-black-3);
  position: relative;
  z-index: 0;

  address {
    margin-top: 12px;
    text-align: center;
  }

  &__content {
    // @extend %home-content;
    padding: 24px 100px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    position: relative;
    gap: 20px;

    &__action {
      display: flex;
      flex-direction: column;
      gap: 24px;

      h4 {
        color: var(--white);
      }

      nav {
        display: flex;
        gap: 16px;
      }

      button,
      a {
        background: transparent;
        color: var(--label-text-4);
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
          color: var(--label-text-7);
        }
      }
    }
  }

  &__bottom {
    color: var(--label-text-4);
    &__content {
      padding: 24px 0;
      position: relative;
    }
    &__copyright {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__up-row {
        display: flex;
        gap: 12px;
        align-items: center;
      }

      &__img-bank {
        display: flex;
        gap: 6px;
        svg {
          min-width: 40px;
          min-height: 32px;
          max-width: 60px;
        }
      }
      &__img-container {
        display: flex;
        gap: 8px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-line {
      position: absolute;
      width: 100%;
      border-bottom: 1px solid var(--label-text-1);
      bottom: 0;
    }

    &__container {
      display: block;
      text-align: center;
    }

    &__bottom {
      display: inline-block;
      text-align: center;
      color: var(--label-text-3);
      font-size: 14px;
      font-weight: 400;
      padding: 24px;
      margin: auto;
      &:hover {
        text-decoration: underline;
        color: var(--label-text-7);
      }
    }
  }

  @include lg {
    &__content {
      padding: 24px 50px;
    }

    &__top {
      gap: 12px;
      &__action {
        nav {
          gap: 8px;
        }

        a {
          font-size: 13.5px;
        }
      }
    }
  }

  @include l1220 {
    &__top__action {
      a {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 1080px) {
    &__top__action {
      &__title {
        &.right {
          text-align: left;
        }
      }
      nav {
        flex-direction: column;
        a {
          text-align: left;
        }
      }
    }
    &__bottom__copyright {
      p {
        font-size: 16px;
      }

      &__img-container {
        position: absolute;
        gap: 8px;
        align-items: flex-end;
        top: -40px;
        left: -10px;
        svg {
          width: 100%;
          height: 25px;
        }
      }
    }

    &__content {
      padding: 24px 40px;
    }
  }

  @include sm {
    &__content {
      padding: 2.4rem;
    }

    &__top__action:nth-child(2) nav {
      align-items: end;

      a {
        text-align: end;
      }
    }

    &__top__action__title {
      font-size: 1.6rem;
      font-weight: 600;
    }

    nav button,
    nav a {
      font-size: 1.4rem;
    }

    &__bottom {
      &__copyright__img-container {
        svg {
          height: 1.8rem;
        }
      }

      &__copyright__up-row {
        font-size: 1.4rem;
      }

      p {
        font-size: 1.2rem;
      }

      &__bottom {
        padding: 0;
        padding-top: 2rem;
        font-size: 1rem;
      }
    }
  }

  @include l670 {
    &__top__action:nth-child(2) nav {
      align-items: end;

      a {
        text-align: right;
      }
    }
  }

  @include b2k {
    &__top {
      padding-bottom: 2.4rem;

      h4 {
        font-size: 1.6rem;
      }

      &__action {
        gap: 2.4rem;

        nav {
          gap: 2.4rem;
        }

        button,
        a {
          font-size: 1.6rem;
        }
      }
    }

    &__bottom {
      &__content {
        padding: 2.4rem 0;
      }
      &__copyright {
        gap: 1.2rem;
        &__img-bank {
          gap: 0.6rem;
          svg {
            min-width: 4rem;
            min-height: 3.2rem;
            max-width: 6rem;
          }
        }

        &__up-row {
          gap: 1.2rem;
          font-size: 1.6rem;

          svg {
            height: 1.6rem;
          }
        }

        &__img-container {
          gap: 0.8rem;
          svg {
            height: 1.6rem;
            width: auto;
          }
        }

        p {
          font-size: 1.4rem;
        }
      }

      &__bottom {
        font-size: 1.4rem;
        padding: 2.4rem;
      }
    }
  }
}

.russian {
  .footer {
    &__top {
      &__action {
        nav {
          gap: 10px;
        }

        a {
          font-size: 14px;
        }
      }
    }

    @include lmm {
      &__top__action a {
        font-size: 12px;
      }
    }

    @media (max-width: 1280px) {
      &__top__action {
        nav {
          flex-direction: column;
        }
      }
      &__bottom__copyright {
        &__img-container {
          position: absolute;
          gap: 8px;
          align-items: flex-end;
          top: -40px;
          left: -10px;
          svg {
            width: 100%;
            height: 25px;
          }
        }
      }
    }

    @include sm {
      &__top nav {
        a {
          font-size: 1.3rem;
        }
      }
    }
  }
}

.deutsch,
.castellano,
.italiano,
.francais,
.portugues {
  .footer {
    &__top {
      flex-direction: column;

      &__action a {
        font-size: 14px;
      }
    }

    @media (max-width: 1080px) {
      &__top {
        flex-direction: row;
      }
    }
  }
}
