@import "../../../style/mixin";

.error-boundary__children {
  .loader-container-center.loader {
    min-height: 250px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.error-block-plug {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  color: var(--white);
  font-size: 12px;
  min-height: 250px;

  .loader-container-center {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__refresh {
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    color: var(--red);
  }

  @include smOrB2k {
    gap: 1rem;
    padding: 2rem;
    font-size: 1.2rem;
    min-height: 15rem;
  }
}
