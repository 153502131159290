@import "../mixin";

.deposit-modal {
  width: 870px;
  min-height: 500px;
  background-color: var(--bg-dark-black-3);
  box-shadow: var(--box-shadow-default);
  border-radius: 20px;
  position: fixed;
  position: relative;
  overflow: auto;
  top: 5vh;
  max-height: 90vh;
  left: calc(50vw - 435px);
  color: var(--white);

  &.template-modal {
    padding: 20px;
  }

  .modal__close {
    right: 20px;
    top: 20px;
  }

  .premium {
    margin-top: 24px;
    background: var(--bg-dark-gray) var(--linear-02red270);
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 300px;
    overflow: hidden;

    .lifetime-premium {
      white-space: nowrap;
      margin-top: 20px;
      display: inline-block;
    }

    h3 {
      font-size: 18px;
      padding: 20px 12px;

      .lifetime-premium__absolute {
        right: -40px;
        top: -24px;
      }

      .bonus-text {
        display: flex;
        gap: 4px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }

    &__banner {
      background: url("../../images/common/bg-premium-deposit.png") no-repeat;
      background-size: cover;
      width: 300px;
    }
  }

  .spinner {
    position: absolute;
    width: 80px;
    height: 80px;
    transform: scale(2);

    left: calc(50% - 40px);
    top: calc(50% - 40px);
  }

  .deposit__info__plug {
    margin-top: 200px;
    text-align: center;
  }

  .deposit__providers {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 24px;
    margin-top: 24px;
    & > div {
      width: 100%;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    .deposit__providers__payments__content,
    .deposit__providers___token__content {
      margin-top: 24px;
      gap: 24px;
      display: flex;
      flex-direction: column;
    }

    &__card {
      padding: 8px 12px;

      &__img {
        max-width: 300px;
      }
    }
    &__card__img,
    &__card__name {
      margin-right: auto;
    }
    .deposit__token__card {
      &.premium-item {
        gap: 8px;
        .deposit__token__card__value {
          margin: 0;
        }
      }

      &__value {
        margin-right: auto;
      }
    }

    .deposit__providers__card img {
      width: auto;
      height: 36px;
      max-width: 100%;
    }

    .deposit__providers__card,
    .deposit__token__card {
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 16px;
      background-color: var(--bg-dark-gray);
      padding: 16px 24px;
      justify-content: space-between;
      cursor: pointer;

      .premium-status {
        background: linear-gradient(180deg, var(--red) 0%, var(--orange) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        font-size: 14px;
      }

      &__title {
        margin-right: auto;
      }

      img {
        user-select: none;
      }
    }
  }

  .deposit__info {
    margin-top: 32px;
    padding: 16px;
    border-radius: 12px;
    background: var(--gradient-deposit-text);

    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      margin-top: 8px;
      font-size: 12px;
      color: var(--label-text-6);
    }
  }
  .custom-button {
    margin-top: 32px;
  }

  @include lsm {
    width: 90vw;
    left: 5vw;

    .crown-red {
      display: none;
    }
  }

  @include l670 {
    min-height: 300px;

    .crown-red {
      display: block;
    }

    & > .premium {
      display: flex;
      flex-direction: column;
      background: url("../../images/common/bg-premium-deposit-mobile.png") no-repeat;
      background-size: cover;
    }

    .premium {
      &__info {
        width: 100%;
      }

      &__banner {
        display: none;
      }
    }

    .deposit__providers {
      flex-direction: column;
    }
  }

  @include b2k {
    width: 87rem;
    min-height: 50rem;
    border-radius: 2rem;
    left: calc(50vw - 43.5rem);

    &.template-modal {
      padding: 2rem;
    }

    .modal__close {
      right: 2rem;
      top: 2rem;
    }

    .premium {
      margin-top: 2.4rem;
      border-radius: 2rem;
      grid-template-columns: 1fr 30rem;

      h3 {
        font-size: 1.8rem;
        padding: 2rem 1.2rem;

        .lifetime-premium__absolute {
          right: -4.8rem;
          top: -3.2rem;
        }

        .bonus-text {
          gap: 0.4rem;
        }
        img {
          width: 2rem;
          height: 2rem;
        }
      }

      &__banner {
        width: 30rem;
      }
    }

    .spinner {
      width: 8rem;
      height: 8rem;
      left: calc(50% - 4rem);
      top: calc(50% - 4rem);
    }

    .deposit__info__plug {
      margin-top: 20rem;
    }

    .deposit__providers {
      gap: 2.4rem;
      margin-top: 2.4rem;

      h3 {
        font-size: 1.6rem;
      }

      .deposit__providers__payments__content,
      .deposit__providers___token__content {
        margin-top: 2.4rem;
        gap: 2.4rem;
      }

      .deposit__token__card {
        &.premium-item {
          gap: 0.8rem;
        }
      }

      .deposit__providers__card,
      .deposit__token__card {
        gap: 1.6rem;
        border-radius: 1.6rem;
        padding: 1.6rem 2.4rem;

        .premium-status {
          font-size: 1.4rem;
        }

        span {
          font-size: 1.4rem;
        }
      }
    }

    .deposit__info {
      margin-top: 3.2rem;
      padding: 1.6rem;
      border-radius: 1.2rem;

      h3 {
        font-size: 1.6rem;
      }

      p {
        margin-top: 0.8rem;
        font-size: 1.4rem;
      }
    }
    .custom-button {
      margin-top: 3.2rem;
    }
  }

  @include sm {
    width: 100svw;
    left: 0svw;
    min-height: 90svh;
    border-radius: 2rem;
    top: 0svh;
    max-height: 100svh;

    &.template-modal {
      padding: 2rem;
    }

    .deposit__providers {
      display: flex;
      flex-direction: column;
    }

    & > .premium {
      display: flex;
      flex-direction: column;
      background: url("../../images/common/bg-premium-deposit-mobile.png") no-repeat;
      background-size: cover;
    }

    .premium__info h3 .crown-red {
      padding: 4px 8px;
      top: -52px;
      right: -64px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .modal__close {
      right: 2rem;
      top: 2rem;
    }

    .deposit__info__plug {
      margin-top: 20rem;
    }

    .deposit__providers {
      gap: 2.4rem;
      margin-top: 2.4rem;
      & > div {
        width: 100%;
      }

      h3 {
        font-size: 1.6rem;
      }

      .deposit__providers__payments__content,
      .deposit__providers___token__content {
        margin-top: 2.4rem;
        gap: 2.4rem;
      }

      .deposit__token__card {
        &.premium-item {
          gap: 0.8rem;
        }
      }

      .deposit__providers__card,
      .deposit__token__card {
        gap: 1.6rem;
        border-radius: 1.6rem;
        padding: 1.6rem;

        span {
          font-size: 1.2rem;
        }
      }
    }

    .deposit__info {
      margin-top: 3.2rem;
      padding: 1.6rem;
      border-radius: 1.2rem;

      h3 {
        font-size: 1.6rem;
      }

      p {
        margin-top: 0.8rem;
        font-size: 1.2rem;
      }
    }
    .custom-button {
      margin-top: 3.2rem;
    }
  }
}
