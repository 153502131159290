@import "../mixin";

.home__upper__footer {
  margin-top: auto;
  background: var(--bg-dark-black-3);
  position: relative;
  z-index: 1;

  &__content {
    padding: 24px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--label-text-1);

    .action-form {
      background-color: transparent;
      font-size: 20px;
      color: var(--label-text-4);
      margin-left: 200px;
      margin-right: auto;

      &:hover {
        text-decoration: underline;
        color: var(--label-text-7);
      }
    }

    &__logo {
      width: 200px;
    }

    .install {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: var(--bg-opacity-red-1);
      border: 1px solid var(--red);
      border-radius: 16px;
      gap: 8px;
      h2 {
        color: var(--white);
        font-size: 16px;

        .accent {
          font-weight: 600;
          color: var(--yellow);
          text-transform: uppercase;
        }
      }

      a {
        background-color: transparent;
        border: none;
        height: 36px;
      }

      img {
        height: 36px;
      }
    }
  }

  &__container {
    padding: 0 100px;
  }

  @include lg {
    &__container {
      padding: 0 50px;
    }
  }

  @include lsm {
    &__container {
      padding: 0 40px;
    }

    &__content {
      // flex-direction: column;
      gap: 12px;
    }
  }

  @include sm {
    &__container {
      padding: 0 2.4rem;
      padding-left: 1rem;
    }

    &__content {
      display: flex;
      // flex-direction: column;
      gap: 1rem;
      align-items: center;
      // align-items: start;

      &__logo {
        width: 20rem;
      }

      .install {
        width: 100%;
        padding: 0.6rem;
        border-radius: 1.2rem;
        gap: 0.4rem;

        h2 {
          font-size: 1rem;
        }

        a,
        img {
          height: 2.2rem;
        }
      }
    }
  }

  @include b2k {
    &__content {
      padding: 2.4rem 0px;

      &__logo {
        width: 20rem;
      }

      .logo {
        height: 2.8rem;
      }

      .install {
        padding: 0.8rem;
        border-radius: 1.6rem;
        gap: 0.8rem;

        h2 {
          font-size: 1.6rem;
        }

        img,
        a {
          height: 3.6rem;
        }
      }
    }
  }
}
