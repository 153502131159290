.cus-select {
  border-radius: 16px;
  background: var(--bg-dark-gray);
  box-shadow: var(--box-shadow-default);
  position: relative;
  cursor: pointer;

  .select__loader {
    margin: auto;
    width: 24px;
    height: 24px;
  }

  &-container label {
    font-size: 14px;
    color: var(--label-text-4);
    margin-bottom: 8px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &__input-text {
    background: transparent;
    outline: none;
    border: none;
    color: var(--white);
  }

  &__value {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
  }

  &__container__title {
    background: var(--bg-dark-gray);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 16px;
    gap: 16px;
    padding: 12px 16px;

    &.open {
      z-index: 11;
    }

    .arrow {
      margin-left: auto;
    }

    &::after {
      display: none;
      position: absolute;
      content: "";
      bottom: 0;
      width: calc(100% - 36px);
      border-bottom: 0.5px solid var(--label-text-4);
    }

    &.open {
      &::after {
        display: block;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;

    &__text {
      color: var(--label-text-4);
      font-size: 14px;
    }
  }

  &__options {
    position: absolute;
    padding: 16px;
    padding-top: 32px;
    width: 100%;
    top: 16px;
    z-index: 3;
    background: var(--bg-dark-gray);
    box-shadow: var(--box-shadow-default);
    border-radius: 0 0 16px 16px;
    max-height: 70svh;
    overflow: auto;

    &__li {
      cursor: pointer;
      align-items: center;
      padding: 16px;
      display: flex;
      gap: 12px;

      &:hover {
        background: var(--bg-dark-black-2);
      }

      &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;
        background: var(--linear-171to71);
        box-shadow: var(--box-shadow-default-2);
      }

      &.selected {
        border-radius: 8px;
        background: var(--bg-dark-black-5);
        box-shadow: var(--box-shadow-default);
        color: var(--white);
      }
    }
  }

  @include lmm {
    &__container__title {
      gap: 12px;
    }
  }

  @include b2k {
    border-radius: 1.6rem;

    .arrow {
      width: 1.4rem;
      height: 1.6rem;
    }

    &-container label {
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }

    &__value {
      font-size: 1.4rem;
    }

    &__container__title {
      border-radius: 1.6rem;
      gap: 1.6rem;
      padding: 1.2rem 1.6rem;

      &::after {
        width: calc(100% - 3.6rem);
      }
    }

    &__title {
      gap: 0.4rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      &__text {
        font-size: 1.4rem;
      }
    }

    &__options {
      padding: 1.6rem;
      padding-top: 3.2rem;
      top: 1.6rem;
      border-radius: 0 0 1.6rem 1.6rem;

      &__li {
        padding: 1.6rem;
        gap: 1.2rem;

        &__icon {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 0.4rem;

          svg {
            width: 1.4rem;
            height: 1.4rem;
          }
        }

        &__label {
          font-size: 1.6rem;
        }

        &.selected {
          border-radius: 0.8rem;
        }
      }
    }
  }
}
