@import "./../template.scss";

.pagination {
  margin-top: auto;

  &__content {
    @extend %home-content;
    display: flex;
    width: 100%;
    padding: 40px 100px;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 100%;
    // padding: 24px 32px;
    width: fit-content;
    display: flex;
    border-radius: 16px;
    box-shadow: var(--box-shadow-default);
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  &__prev,
  &__next {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--white);
    border: none;
    background: var(--bg-dark-black-3);
    width: 60px;
    height: 60px;
    padding: 8px;

    &:disabled {
      opacity: 0.7;
      cursor: default;
      path {
        stroke-opacity: 0.7;
      }
    }

    path {
      stroke-opacity: 1;
    }
  }

  &__pages {
    display: flex;
    gap: 18px;
    align-items: center;
    gap: 8px;

    .page {
      border-radius: 8px;
      width: 60px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--bg-dark-black-3);
      cursor: pointer;
      color: var(--white);
    }

    .current {
      background-color: var(--primary);
    }
  }

  @include l1220 {
    &__prev,
    &__next {
      width: 48px;
      height: 48px;
    }

    &__pages .page {
      width: 48px;
      height: 48px;
    }
  }

  @include lsm {
    &__content {
      padding: 40px;
    }
  }

  @include sm {
    &__content {
      padding: 4rem 2.4rem;
    }
    &__prev,
    &__next {
      width: 4rem;
      height: 4rem;
      max-width: 50px;
      max-height: 50px;
    }

    &__pages .page {
      width: 3.9rem;
      height: 3.9rem;
      font-size: 1.2rem;
      max-width: 50px;
      max-height: 50px;
    }
  }

  @include b2k {
    &__content {
      padding: 4rem 5rem;
    }

    &__container {
      border-radius: 1.6rem;
      gap: 0.8rem;
    }

    &__prev,
    &__next {
      border-radius: 0.8rem;
      gap: 0.8rem;
      width: 5rem;
      height: 5rem;
      padding: 0.8rem;

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &__pages {
      gap: 0.8rem;

      .page {
        border-radius: 0.8rem;
        width: 5rem;
        height: 5rem;
        font-size: 1.8rem;
      }
    }
  }
}
