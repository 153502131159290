.cus-checkbox {
  transition: 0.2s;

  &__checkbox {
    &:checked:after {
      content: "\2713";
      color: var(--white);
      font-weight: 900;
      // position: absolute;
      // font-size: 22px;
      // top: -12px;
      // left: 2px;
    }
    position: relative;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border-radius: 4px;
    border: 1px solid #c3c3c3;
    background: var(--linear-171to71);
    box-shadow: var(--box-shadow-default-2);

    width: 20px !important;
    height: 20px !important;
    appearance: none;
    border-radius: 4px;
    border: 1px solid #545454;

    &__container {
      display: flex;
      align-items: center;
    }
  }

  &__div {
    width: 100%;
  }

  &__title {
    color: var(--label-text-4);
    font-size: 14px;
  }

  &__count {
    margin-left: auto;
    color: var(--label-text-4);
    font-size: 14px;
  }

  &.checked {
    border-radius: 8px;
    background: #1b1b1b;
    box-shadow: 0px 29px 58px 0px rgba(0, 0, 0, 0.25),
      0px 4px 13px 0px rgba(43, 159, 196, 0.03) inset;

    .cus-checkbox__title {
      color: var(--white);
    }

    .cus-checkbox__count {
      color: var(--white);
    }
  }

  @include b2k {
    &__checkbox {
      &:checked:after {
        font-size: 1.2rem;
      }

      border-radius: 0.4rem;
      border-width: 0.1rem;

      width: 2rem !important;
      height: 2rem !important;
      border-radius: 0.4rem;
      border-width: 0.1rem;
    }

    &__count,
    &__title {
      font-size: 1.4rem;
    }

    &.checked {
      border-radius: 0.8rem;
    }
  }
}
