@import "../mixin";

.camera-settings {
  $paddingCommon: 24px;

  &-modal {
    width: 568px;
    max-height: 90vh;
    top: 5vh;
    border-radius: 20px;
    background-color: var(--bg-dark-black-3);
    box-shadow: var(--box-shadow-default);
    left: calc(50vw - 284px);
    position: fixed;
    color: var(--white);
    padding: $paddingCommon;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;

    .cus-select-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .modal__close {
      top: $paddingCommon;
      right: $paddingCommon;
    }

    .button-container {
      margin-top: 80px;
      width: 100%;
      button {
        width: 100%;
      }
    }

    .cus-select__options {
      max-height: 280px;
    }
  }

  &__title {
    text-align: center;
  }

  &__user {
    background-color: var(--bg-white-05);
    border-radius: 20px;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    // video {
    //   max-width: 520px;
    //   max-height: 240px;
    // }
  }

  &__video {
    position: relative;
    z-index: 2;
    &__bg {
      position: absolute;
      z-index: 1;
      width: 100%;
      filter: blur(15px);

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__canvas {
    display: none;
  }

  &__no-camera {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: 104px;

      path {
        fill: var(--white);
        fill-opacity: 0.2;
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__loader {
    position: absolute;
    background-color: var(--bg-dark-black-2);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  @include sm {
    &-modal {
      width: 90vw;
      max-height: 90vh;
      top: 5vh;
      border-radius: 2rem;
      left: 2.5rem;
      gap: 2.4rem;

      button {
        font-size: 1.4rem;
      }
    }

    &__user {
      max-height: 20rem;
      min-height: 16rem;
    }
  }

  @include b2k {
    &-modal {
      width: 80rem;
      padding: 2.4rem;
      gap: 2.4rem;
      left: calc(50vw - 40rem);
    }
  }
}
