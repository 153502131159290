@import "../mixin";
@import "./model-card.scss";

.home__content {
  &__container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .home__models {
      gap: 12px;
      height: 100%;
      max-width: 1920px;

      @for $i from 1 through 6 {
        &.model-column-#{$i} {
          margin: auto;
          place-content: center;
          grid-template-columns: repeat(#{$i}, 1fr);
        }
      }
    }

    &.collapsed {
      width: 100%;
    }
  }

  .spinner-container {
    height: calc(100% - 200px);
    min-height: 50vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .spinner {
      margin: auto;
    }
  }

  &__header {
    width: 100%;
    margin-top: 32px;

    &__text {
      position: relative;
      display: block;
      top: 28px;
      text-align: center;
      color: var(--white);
      font-size: 18px;
      font-weight: 500;
    }

    &__img__container {
      width: 100%;
      position: relative;

      .gradient {
        width: 100%;
      }

      .svg-center-title {
        position: absolute;
        left: calc(50% - 6px);
        width: auto;
        width: 24px;

        &.trans {
          left: calc(50% - 8px);
          top: -4px;
        }

        path {
          fill: var(--primary);
        }
      }

      svg {
        position: absolute;
      }
    }
  }

  .home__models {
    display: grid;
    padding: 16px;
    padding-top: 50px;
    padding-bottom: 0px;
    width: 100%;
    justify-content: center;
    position: relative;

    &__containers {
      // min-height: 1400px;
      min-height: 400px;
      // height: calc(100% - 260px);
    }
  }

  @include lsm {
    &__header {
      margin-top: 8px;
    }

    .svg-center-title {
      left: calc(50% - 10px);
      top: 8px;
      &.trans {
        left: calc(50% - 12px);
      }
    }

    &__container.collapsed {
      .home__models {
        grid-template-rows: repeat(5, 150px);
      }
    }
  }

  @media (pointer: coarse) {
    @media screen and (max-width: 1250px) {
      &__container {
        .home__models {
          grid-template-columns: repeat(4, 1fr) !important;
        }
      }
    }
    @media screen and (max-width: 980px) {
      &__container {
        .home__models {
          grid-template-columns: repeat(3, 1fr) !important;
        }
      }
    }
  }

  @include sm {
    &__header {
      margin-top: 0.4rem;
    }

    @media screen and (max-width: 550px) {
      &__container {
        .home__models {
          padding-top: 5rem;
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }
  }

  @include b2k {
    &__header {
      &__text {
        top: 1.6rem;
        font-size: 1.8rem;
      }
      &__img__container {
        .svg-center-title {
          left: calc(50% - 1rem);
          top: -0.8rem;
          width: 2.4rem;
          height: 2.4rem;

          &.trans {
            left: calc(50% - 1.2rem);
            top: -0.6rem;
          }
        }
      }
    }

    &__container {
      width: calc(100% - 22rem);
      .home__models {
        gap: 1.2rem;
        max-width: 192rem;
      }
    }

    & > .home__filter__container {
      .home__filter__menu__item__buttons.fixed-button {
        bottom: -2rem;
        padding: 2rem;
        border-radius: 1.2rem;
      }
    }

    .spinner-container {
      height: calc(100% - 20rem);
    }

    &__header {
      margin-top: 3.2rem;

      &__text {
        top: 2.8rem;
        font-size: 1.8rem;
      }
    }

    .home__models {
      padding: 2.4rem;
      padding-top: 5rem;

      &__containers {
        min-height: 40rem;
      }
    }
  }
}

@import url("./upper-footer.scss");
