.find {
  padding: 12px 20px;
  background: var(--bg-dark-gray);
  box-shadow: var(--box-shadow-default);
  border-radius: 16px;
  color: var(--label-text-4);
  display: flex;
  gap: 12px;
  width: 364px;

  &__glass {
    background: transparent;
    &:hover {
      svg path {
        fill: var(--white);
      }
    }
  }

  &__input {
    background: transparent;
    outline: none;
    border: none;
    color: var(--white);
    width: 100%;
  }

  @include b2k {
    padding: 1.2rem 2rem;
    border-radius: 1.6rem;
    gap: 1.2rem;
    width: 36.4rem;

    &__input {
      font-size: 1.4rem;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
