@import "../mixin";

.chose-lang {
  position: relative;
  color: var(--white);
  padding: 10px 7px;
  border-radius: 16px;
  background-color: transparent;
  color: var(--white);

  ul {
    display: none;
    min-width: 148px;
  }

  &__plug {
    display: none;
    width: 100%;
  }

  .lang-item {
    display: flex;
    align-items: center;
    min-width: 116px;
    font-size: 18px;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      min-width: 116px;
      font-size: 18px;
      gap: 8px;
      background-color: transparent;
      color: var(--white);
    }

    &.select {
      font-weight: 600;
    }

    &.main {
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      min-width: fit-content;

      &:hover,
      &:focus {
        color: var(--gray-black-hover);

        .arrow {
          opacity: 0.6;
        }
      }
    }

    span {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    img {
      width: 20x;
      height: 14px;

      &.arrow {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:hover,
  &:focus {
    .chose-lang__plug {
      display: flex;
      position: absolute;
      top: 16px;
      left: 0;
      background-color: transparent;
      height: 44px;
      z-index: 14;
      width: 120px;
    }
    .close + .chose-lang__plug {
      display: none;
    }

    ul {
      top: 44px;
      z-index: 15;
      right: calc(-50% + 5px);

      &.ul-for-only-flag {
        right: -12px;
      }

      &.ul-for-mobile {
        right: -10px;
      }

      border-radius: 16px;
      background: var(--bg-dark-gray);
      box-shadow: 0px 29px 55px 0px rgba(0, 0, 0, 0.67),
        0px 4px 13px 0px rgba(43, 159, 196, 0.03) inset, 12px 43px 12px 0px rgba(0, 0, 0, 0.25),
        -28px 19px 16px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      list-style: none;
      overflow: hidden;

      &.close {
        display: none;
      }

      li {
        padding: 8px 28px 8px 20px;

        &:hover {
          cursor: pointer;
          background-color: var(--bg-dark-black);
        }
      }
    }
  }

  @include l1220 {
    // width: 80px;

    span.text {
      font-size: 14px !important;
    }

    &:hover,
    &:focus {
      .chose-lang__plug {
        left: -80px;
      }
    }
  }

  @include sm {
    padding: 1.6rem 1.2rem;
    border-radius: 1.2rem;
    min-width: 9.6rem;

    ul {
      min-width: 14.8rem;
    }

    &:hover,
    &:focus {
      .chose-lang__plug {
        z-index: 1;
      }
    }

    .lang-item {
      gap: 0.8rem;
      min-width: 8rem;
      font-size: 1.4rem;

      &.main {
        font-size: 1.8rem;
        grid-template-columns: 2.8rem 4.4rem;
        min-width: 4rem;
        display: flex;
      }

      img {
        width: 2rem;
        height: 2rem;

        &.arrow {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    &:hover {
      .chose-lang__plug {
        top: 3rem;
        height: 4.4rem;
      }
      ul {
        top: 4.8rem;
        border-radius: 1.2rem;
        li {
          padding: 0.8rem 2.8rem 0.8rem 2rem;
        }
      }
    }
  }

  @include b2k {
    padding: 1rem 0.7rem;
    border-radius: 1.6rem;

    ul {
      min-width: 14.8rem;
    }

    .lang-item {
      min-width: 10.6rem;
      font-size: 1.8rem;
      gap: 0.8rem;

      button {
        min-width: 10.6rem;
        font-size: 1.3rem;
        gap: 0.8rem;
      }

      &.main {
        font-size: 1.4rem;
      }

      span {
        font-size: 1.4rem;
        gap: 0.4rem;
      }

      img {
        width: 2rem;
        height: 1.8rem;

        &.arrow {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }

    &__arrow {
      width: 1.4rem;
      height: 1.6rem;
    }

    &:hover,
    &:focus {
      .chose-lang__plug {
        top: 1.6rem;

        height: 4.4rem;
        width: 12rem;
      }

      ul {
        top: 4.4rem;
        right: calc(-50% + 0.5rem);
        border-radius: 1.6rem;
        border-radius: 1.2rem;

        &.ul-for-only-flag {
          right: -1.2rem;
        }

        &.ul-for-mobile {
          right: -1rem;
        }

        li {
          padding: 0.8rem 2.8rem 0.8rem 2rem;
        }
      }
    }
  }
}
