@import "../_mixin.scss";

.app-modal {
  width: 460px;
  top: 20vh;
  max-height: 70vh;
  position: fixed;
  left: calc(50vw - 230px);
  color: var(--white);
  background: url("../../images/common/app-bg.png") no-repeat;
  background-size: cover;
  padding: 32px;
  border-radius: 20px;
  overflow: hidden;

  .modal__close {
    top: 32px;
    right: 32px;
  }

  .app__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h1 {
      font-size: 24px;
      letter-spacing: -1.08px;
      max-width: 80%;
      margin: 0;
    }

    p {
      max-width: 50%;
      font-size: 14px;
      color: var(--label-text-5);
    }

    &__img {
      position: absolute;
      right: 0px;
      top: 60px;
    }
  }

  .app__items {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    &__item {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 8px;
      align-items: center;

      .app__store-link {
        padding: 12px;
        border-radius: 16px;
        background: var(--bg-dark-gray);
        box-shadow: var(--box-shadow-default);
        display: flex;
        align-items: center;
        font-size: 12px;

        &__img {
          margin-right: 8px;
        }

        &__copy {
          margin-left: auto;
          background-color: transparent;

          &:hover {
            svg path {
              fill: var(--white);
            }
          }
        }
      }
    }
  }

  @include sm {
    width: 95vw;
    top: 5vh;
    max-height: 90vh;
    left: 2.5vw;
    padding: 2rem;
    border-radius: 2rem;

    .modal__close {
      top: 2rem;
      right: 2rem;
    }

    .app__content {
      gap: 0.4rem;
      h1 {
        font-size: 2rem;
        max-width: 80%;
      }

      p {
        font-size: 1.2rem;
      }

      &__img {
        top: 3.2rem;
      }
    }

    .app__items {
      margin-top: 3.6rem;
      gap: 0.8rem;

      &__item {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px;

        .app__store-link {
          padding: 1.2rem;
          border-radius: 1.6rem;
          font-size: 1.2rem;

          &__img {
            margin-right: 0.8rem;
          }
        }
      }

      .custom-button {
        font-size: 1.6rem;
        padding: 0.8rem 2rem;
      }
    }
  }
}
