.menu-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000080;
  z-index: 5 !important;
  overflow: hidden !important;
}

.menu-modal {
  width: 248px;
  background-color: var(--bg-dark-black-3);
  height: 100vh;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 60px;
  border-radius: 12px 12px 0 0;

  .custom-button {
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 16px;
  }

  .header__lang {
    margin: auto;
  }

  .modal__close {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    &__buttons {
      display: flex;
      justify-content: space-around;

      button.custom-button {
        padding: 12px 32px;
      }
    }
  }

  .cus-select {
    margin: auto;
    margin-top: 20px;
    min-width: calc(100% - 24px);
    width: calc(100% - 24px);

    &__title__text {
      font-size: 11px;
    }

    &__value {
      font-size: 12px;
    }

    &__container__title {
      gap: 8px;
      width: 100%;
    }
    svg {
      display: none;
    }
    &__options {
      li {
        padding: 8px;
        align-items: center;
        color: var(--label-text-5);

        &.selected {
          color: var(--white);
        }
      }

      svg {
        display: block;
      }
    }
  }
  .home__filter {
    &__container {
      margin-top: 28px;
      padding-top: 0;
      box-shadow: none;
      min-height: 100%;
      padding-bottom: 128px;
    }

    &__menu__item__buttons {
      position: fixed;
      bottom: -20px;
      background-color: var(--bg-dark-black-3);
      width: 248px;
      right: 0;
      height: 80px;
      padding-top: 20px;
    }

    &__menu__accordion__summary {
      span {
        font-size: 16px;
      }
    }
  }

  @include lsm {
    .home__filter__menu__accordion__summary {
      span {
        font-size: 16px;
      }
    }
  }
}

@include sm {
  .MuiSlider-valueLabelLabel {
    font-size: 1.4rem;
  }

  .home__filter__menu__item__buttons {
    padding: 1.2rem 1.4rem;
    font-size: 1.4rem;
  }

  .menu-modal {
    .cus-select__options__li__label {
      font-size: 1.2rem;
    }
  }
}

.header__content.z-index1201 {
  z-index: 1201;
  position: relative;
}

.russian {
  .menu-modal {
    .custom-button {
      font-size: 11.5px;
    }
  }

  @include sm {
    &.menu-modal {
      .cus-select__container__title {
        padding: 12px;
      }
      .cus-select__value {
        font-size: 1.2rem;
      }
    }
  }
}
