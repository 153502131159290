:root {
  // color
  --white: #fff;
  --black: #000;
  --primary: #cc0a0a;
  --red: #cc0a0a;
  --red-pink: #ff4a4a;
  --primary-pale: #ff8a8a;
  --violet: #ff00e5;
  --yellow: #ffcd1c;
  --orange: #ffb32b;
  --green: #00ff38;
  --green2: #1da83b;
  --gray: #c6c6c6;
  --dark-gray: #767676;
  --black-gray: #2e2e2e;

  //

  --facebook: #1877f2;
  --twitter: #1da1f2;
  --twitter: #f00073;
  --onlyfans: #fff;

  // opacity
  --primary-opacity08: #cc0a0ad6;
  --primary-opacity05: #cc0a0a85;

  // bg
  --bg-white-03: rgba(255, 255, 255, 0.03);
  --bg-white-05: rgba(255, 255, 255, 0.05);
  --bg-white-07: rgba(255, 255, 255, 0.07);
  --bg-white-5: rgba(255, 255, 255, 0.5);

  --bg-gray-8: #818181;

  --bg-dark-gray: #191919;
  --bg-dark-black: #080808;
  --bg-dark-black-80: #08080880;
  --bg-dark-black-2: #0f0f0f;
  --bg-dark-black-3: #121212;
  --bg-dark-black-4: #222;
  --bg-dark-black-5: #232323;

  --bg-opacity-black-3: rgba(0, 0, 0, 0.3);
  --bg-opacity-black-15: rgba(0, 0, 0, 0.15);
  --bg-opacity-green-05: rgba(0, 255, 0, 0.05);
  --bg-opacity-red-06: rgba(255, 0, 0, 0.06);
  --bg-opacity-red-1: rgba(255, 0, 0, 0.1);
  --bg-opacity-red-2: rgba(255, 0, 0, 0.2);
  --bg-opacity-red-3: rgba(255, 0, 0, 0.3);
  --bg-opacity-red-15: rgba(255, 0, 0, 0.15);

  --border-color: "#c3c3c3";

  // linear-gradient

  --linear-171to71: linear-gradient(180deg, rgba(171, 171, 171, 0.2) 0%, rgba(71, 71, 71, 0) 100%);
  --linear-red02to0: linear-gradient(180deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0) 100%);
  --linear-02red: linear-gradient(0deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0) 100%);
  --linear-02red90: linear-gradient(90deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0) 100%);
  --linear-02red270: linear-gradient(270deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0) 100%);

  --linear-opacity-red: linear-gradient(
    0deg,
    rgba(204, 10, 10, 0.18) 0%,
    rgba(204, 10, 10, 0.18) 100%
  );

  --linear-opacity-red-5: linear-gradient(
    0deg,
    rgba(204, 10, 10, 0.5) 0%,
    rgba(204, 10, 10, 0.5) 100%
  );

  --linear-opacity-red2: linear-gradient(
    103deg,
    rgba(255, 35, 35, 0.07) 40.67%,
    rgba(255, 35, 35, 0.07) 40.68%,
    rgba(255, 193, 35, 0) 112.66%,
    rgba(255, 35, 35, 0) 112.66%
  );

  // name

  --gradient-balance-block: linear-gradient(
      193deg,
      rgba(25, 27, 28, 0.1) 9.35%,
      rgba(25, 25, 25, 0.1) 144.17%
    ),
    radial-gradient(
      729.82% 133.48% at 3.03% 17.78%,
      rgba(204, 11, 11, 0.2) 0%,
      rgba(204, 11, 11, 0) 100%
    );

  --gradient-deposit-text: linear-gradient(
    103deg,
    rgba(255, 207, 35, 0.07) 40.67%,
    rgba(255, 233, 35, 0.07) 40.68%,
    rgba(255, 193, 35, 0) 112.66%,
    rgba(251, 255, 35, 0) 112.66%
  );

  // box-shadow

  --box-shadow-default: 0px 28px 60px 0px rgba(0, 0, 0, 0.25),
    0px 4px 12px 0px rgba(44, 160, 196, 0.03) inset;
  --box-shadow-default-2: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
  --box-shadow-default-3: 0px 4px 4px 0px rgba(95, 95, 95, 0.25);
  --box-shadow-focus: 0px 1px 2px #ffffff;

  // text
  --primary-text: #fff;
  --label-text-1: rgba(255, 255, 255, 0.1);
  --label-text-2: rgba(255, 255, 255, 0.2);
  --label-text-3: rgba(255, 255, 255, 0.3);
  --label-text-4: rgba(255, 255, 255, 0.4);
  --label-text-5: rgba(255, 255, 255, 0.5);
  --label-text-6: rgba(255, 255, 255, 0.6);
  --label-text-7: rgba(255, 255, 255, 0.7);

  --primary-accent: #cc0a0a;
  --yellow-text: #ffdd00;

  // dimensions
  --home-width-content: 1440px;

  // Transaction type

  --transaction-spend: rgba(255, 179, 64, 0.1);
  --transaction-refund-plus: rgba(242, 104, 211, 0.1);
}
