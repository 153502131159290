@import "../mixin";

.notify-modal {
  position: absolute;
  z-index: 11;
  top: 2vh;
  width: 400px;
  left: calc(50vw - 200px);
  background-color: var(--bg-dark-black-3);
  border-radius: 20px;
  box-shadow: var(--box-shadow-default);
  color: var(--white);
  padding: 4px;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;

    h1 {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    p {
      font-size: 14px;
      color: var(--bg-white-5);
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    gap: 8px;
    button {
      width: 100%;
    }
  }

  &__icon {
    svg {
      width: 60px;
      height: 60px;
      path {
        fill: var(--bg-white-5);
      }
    }
  }

  @include sm {
    top: 2vh;
    width: 75vw;
    left: 12.5vw;
    border-radius: 2rem;

    &__content {
      padding: 1.2rem;
      gap: 1.2rem;

      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &__buttons {
      gap: 0.8rem;
      button {
        font-size: 1.6rem;
      }
    }

    &__icon {
      svg {
        width: 6rem;
        height: 6rem;
      }
    }
  }

  @include b2k {
    top: 6vh;
    width: 40rem;
    left: calc(50vw - 20rem);
    border-radius: 2rem;
    padding: 2rem;

    .absolute__controls {
      height: 4rem;

      button {
        width: 3.2rem;
        height: 3.2rem;

        svg {
          height: 1.8rem;
          width: 1.8rem;
        }

        .icon-hide {
          height: 0.3rem;
          width: 2rem;
          margin: auto;
        }
      }
    }

    &__content {
      top: 6vh;
      left: calc(50vw - 20rem);
      border-radius: 2rem;
      padding: 0.4rem;

      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &__buttons {
      gap: 0.8rem;
    }

    &__icon {
      svg {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
